const GHSTOUSD = 10;

const NGNTOUSD = 810;

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const currencySymbolConversion = (price, currencyCode, status) => {
  const newPrice = parseInt(price);

  if (
    status !== "Stripe Paid" &&
    status !== "Paystack Paid" &&
    status !== "Stripe Not Paid" &&
    status !== "PayStack Not Paid"
  ) {
    switch (currencyCode) {
      case "USD":
      default:
        return `$${numberWithCommas(newPrice)}`;
      case "NGN":
        return `N${numberWithCommas(newPrice * NGNTOUSD)}`;
      case "GHS":
        return `C${numberWithCommas(newPrice * GHSTOUSD)}`;
    }
  } else {
    switch (currencyCode) {
      case "USD":
      default:
        return `$${numberWithCommas(newPrice / 100)}`;
      case "NGN":
        return `N${numberWithCommas(newPrice / 100)}`;
      case "GHS":
        return `C${numberWithCommas(newPrice / 100)}`;
    }
  }
};

export const currencyConversion = (amount, currency) => {
  if (amount) {
    switch (currency) {
      case "NGN":
        return amount * NGNTOUSD * 100;
      case "GHS":
        return amount * GHSTOUSD * 100;
      default:
        return amount * 100;
    }
  } else {
    return 0;
  }
};

export const getLastDate = () => {
  const curr = new Date();
  const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
  const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

  return lastday.toString();
};

export function numberWithCommas2(x) {
  const x1 = x / 100;
  return x1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertDate = (datestr) => {
  const date = new Date(datestr);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const convertKey = (str) => {
  switch (str) {
    case "dealId":
      return "Deal Id";
    case "fname":
      return "First Name";
    case "lname":
      return "Last Name";
    case "phoneNumber":
      return "Phone Number";
    case "dealPrice":
      return "Deal Price";
    case "itemPrice":
      return "Item Price";
    case "currencyCode":
      return "Currency";
    case "expiryDate":
      return "Expiry Date";
    case "ringSize":
      return "Ring Size";
    case "orderDate":
      return "Order Date";
    case "id":
      return "Order Id";
    default:
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export const convertValue = (data, key) => {
  switch (key) {
    case "dealPrice":
      return currencySymbolConversion(
        data[key],
        data["currencyCode"],
        data["status"]
      );
    case "itemPrice":
      return currencySymbolConversion(
        data[key],
        data["currencyCode"],
        data["status"]
      );
    case "expiryDate":
    case "orderDate":
      return convertDate(data[key]);

    default:
      return data[key];
  }
};
