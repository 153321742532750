import styled from "styled-components";
import { Input, Button, Select, DatePicker } from "antd";

const { TextArea } = Input;

export const AdminPanelContainer = styled.div``;

export const NavContainer = styled.div`
  text-align: center;
  border: 1px solid rgb(233, 230, 230);
  padding: 5px 20px;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
`;

export const MenuItem = styled.div`
  background-color: ${({ highlight }) => (highlight ? "#a62e51" : null)};
  display: inline-block;
  padding: 10px 25px;
  border-radius: 10px;
  color: ${({ highlight }) => (highlight ? "#fff" : "#000")};
  cursor: pointer;
`;

export const FormPaneContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

export const FindText = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 38px;
  margin-top: 40px;
`;

export const InputWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InputFormPane = styled(Input)`
  max-width: 600px;
  padding: 10px 15px;
  font-size: 16px;
`;
export const ButtonFormPane = styled(Button)`
  color: #fff;
  background: #a62e51;
  height: 45px;
  width: 100px;
  margin-left: 20px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`;
export const TrackTip = styled.div`
  font-style: italic;
  color: grey;
`;

export const TrackTableContainer = styled.div`
  margin-top: 50px;
  width: 1000px;
  height: 500px;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  ${({ height }) =>
    height &&
    `
  height: ${height};
  `}
`;

export const TableHeaders = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const NewOrderContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const FormWrapper2 = styled.div`
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  width: auto;
  align-items: center;
`;

export const InputWrapper2 = styled.div`
  width: 400px;
`;
export const InputWrapper2b = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  text-align: left;
  padding: 0;
  :last-child {
    width: 400px;
  }
`;

export const NewOrderInput = styled(Input)`
  max-width: 400px;
  padding: 10px 15px;

  position: relative;
`;

export const NewOrderSelect = styled(Select)`
  width: 200px;
  padding: 10px 5px;
  position: relative;
`;

export const DatePickerC = styled(DatePicker)``;

export const TextAreaC = styled(TextArea)``;

export const FormBtnWrapper = styled.div`
  text-align: center;
  margin-top: 34px;
`;

export const UpdateHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const StatusButton = styled.div`
  cursor: pointer;
  color: #fff;
  background: #a62e51;
  width: 80px;
  text-align: center;
  padding: 8px 15px;
  border-radius: 10px;
  margin-left: 20px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: 600; */
`;

export const ButtonFormPaneClose = styled(ButtonFormPane)`
  background: grey;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  :last-child {
    margin-left: 20px;
  }
`;

export const CompletedOrdersContainer = styled.div``;
