import React from "react";
import "./FormViewer.css";
import { AiFillQuestionCircle } from "react-icons/ai";
import { TrackTip } from "./styles";
import TrackTable from "./TrackTable";

const FormViewer = (props) => {
  return <div>{props.data ? <TrackTable data={props.data} /> : null}</div>;
};

export default FormViewer;
