import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import "./EditOrders.css";
import { message } from "antd";
import * as EmailValidator from "email-validator";
import dayjs from "dayjs";
import {
  BtnContainer,
  ButtonFormPane,
  ButtonFormPaneClose,
  FormBtnWrapper,
  FormWrapper,
  InputWrapper2,
  InputWrapper2b,
  NewOrderContainer,
  NewOrderInput,
  NewOrderSelect,
  TextAreaC,
} from "../styles";
import { DatePicker } from "antd";
import { options } from "./NewOrder";

const EditOrders = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [lname, setlname] = useState("");
  const [status, setstatus] = useState("");
  const [edd, setedd] = useState("");
  const [email, setemail] = useState("");
  const [description, setdesription] = useState("");

  const success = (succ) => {
    messageApi.open({
      type: "success",
      content: succ,
      style: {
        marginTop: "18vh",
      },
    });
  };
  const error = (err) => {
    messageApi.open({
      type: "error",
      content: err,
      style: {
        marginTop: "18vh",
      },
    });
  };

  const fetchData = useCallback(async () => {
    await Axios.get(
      `${process.env.REACT_APP_SERVER}/getupdateorder/${props.data}`
    )
      .then((res) => {
        if (res) {
          setinvoiceNumber(res.data.data[0].invoiceNumber);
          setlname(res.data.data[0].lname);
          setstatus(res.data.data[0].status);
          setedd(transformDate(res.data.data[0].edd));
          setemail(res.data.data[0].email);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  }, [props.data]);

  useEffect(() => {
    fetchData();
  }, [props.data, fetchData]);

  const transformDate = (date) => {
    const dateArr = date?.split("/");
    const newDate = `${dateArr[2]}-${
      dateArr[1]?.length < 2 ? `0${dateArr[1]}` : dateArr[1]
    }-${dateArr[0]?.length < 2 ? `0${dateArr[0]}` : dateArr[0]}`;
    return dayjs(newDate, "YYYY-MM-DD");
  };

  const changeHandler = (e) => {
    let id = e?.target?.id;
    let value = e.target.value;
    switch (id) {
      case "invoiceNumber":
        setinvoiceNumber(value);
        break;
      case "lname":
        setlname(value);
        break;
      case "email":
        setemail(value);
        break;
      case "description":
        setdesription(value);
        break;

      default:
        break;
    }
  };

  const checkValues = () => {
    if (EmailValidator.validate(email.toLowerCase())) {
      if (edd) {
        postdata();
      } else {
        error("Error with EDD, check and correct it");
      }
    } else {
      error("Error with Email, check and correct it");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    checkValues();
  };

  const postdata = async () => {
    let dd = new Date();
    let ddm =
      dd.getDate() +
      "/" +
      (dd.getMonth() + 1) +
      "/" +
      dd.getFullYear() +
      "  " +
      dd.getHours() +
      ":" +
      dd.getMinutes() +
      "GMT";
    let values = {
      orderId: invoiceNumber + lname,
      invoiceNumber: invoiceNumber,
      lname: lname,
      status: status,
      edd: edd,
      email: email,
      description: description,
      date: ddm,
      dateReal: dd,
    };

    await Axios.post(`${process.env.REACT_APP_SERVER}/postupate`, values).then(
      (res) => {
        if (res.data.data === "Update Saved") {
          props.close();
          success(res.data.data);
        } else {
          error(res.data.data);
        }
      }
    );
  };

  if (invoiceNumber) {
    return (
      <div key={props.key}>
        <NewOrderContainer>
          <FormWrapper>
            {contextHolder}
            <InputWrapper2>
              <NewOrderInput
                placeholder="Invoice Number"
                id="invoiceNumber"
                value={invoiceNumber}
                onChange={changeHandler}
              />
            </InputWrapper2>
            <InputWrapper2>
              <NewOrderInput
                placeholder="Last Name"
                id="lname"
                value={lname}
                onChange={changeHandler}
              />
            </InputWrapper2>
          </FormWrapper>
          <FormWrapper>
            <InputWrapper2b>
              <NewOrderSelect
                options={options}
                defaultValue="ordering"
                size="large"
                id="status"
                value={status}
                onChange={(value) => setstatus(value)}
              />
              <DatePicker
                size="large"
                value={edd}
                onChange={(value) => {
                  if (value) {
                    const dd = `${value.date()}/${
                      value.month() + 1
                    }/${value.year()}`;
                    setedd(transformDate(dd));
                  }
                }}
              />
            </InputWrapper2b>

            <InputWrapper2b>
              <NewOrderInput
                placeholder="Email"
                id="email"
                value={email}
                onChange={changeHandler}
              />
            </InputWrapper2b>
          </FormWrapper>
          <FormWrapper>
            <TextAreaC
              rows={4}
              placeholder="Description"
              id="description"
              value={description}
              onChange={changeHandler}
            />
          </FormWrapper>
          <BtnContainer>
            <FormBtnWrapper>
              <ButtonFormPane onClick={submitHandler}> Submit </ButtonFormPane>
            </FormBtnWrapper>
            <FormBtnWrapper>
              <ButtonFormPaneClose onClick={props.close}>
                {" "}
                Close{" "}
              </ButtonFormPaneClose>
            </FormBtnWrapper>
          </BtnContainer>
        </NewOrderContainer>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default EditOrders;
