import React from "react";

import {
  DealsDrawerWrapper,
  Footer,
  ImageWrapper,
  ItemWrapper,
  Key,
  Value,
  ViewBtn,
} from "./DealsSearch.style";
import { convertKey, convertValue } from "./helpers";

const DealsDrawer = ({ data }) => {
  const keys = Object.keys(data);
  const noKeys = ["image", "itemId", "onlineStoreUrl", "", "transactionId"];
  const newKeys = keys.filter((key) => {
    return !noKeys.includes(key);
  });

  return (
    <>
      <DealsDrawerWrapper>
        <ImageWrapper>
          <img src={data.image} alt={data.title} width={250} />
        </ImageWrapper>
        {!!newKeys &&
          newKeys.length &&
          newKeys.map((key) => {
            if (data[key]) {
              return (
                <ItemWrapper>
                  <Key> {convertKey(key)}:</Key>
                  <Value> {convertValue(data, key)} </Value>
                </ItemWrapper>
              );
            }
            return null;
          })}
      </DealsDrawerWrapper>
      <Footer>
        <ViewBtn
          type="primary"
          onClick={() => {
            window.open(data["onlineStoreUrl"], "_blank");
          }}
        >
          View Product
        </ViewBtn>
      </Footer>
    </>
  );
};

export default DealsDrawer;
