import React, { useState, useEffect } from "react";
import { Progress } from "reactstrap";
import { IoMdRefreshCircle } from "react-icons/io";
import Axios from "axios";

import "./Progress.css";
import { RiSafariFill } from "react-icons/ri";

const Progressx = () => {
  const [datay, setdatay] = useState(0);
  const [dataa, setdataa] = useState(0);
  const [datal, setdatal] = useState(0);
  const [dataac, setdataac] = useState(0);

  const [bool, setbool] = useState(true);

  useEffect(() => {
    getinfo();
  }, [bool]);

  const refresh = () => {
    setbool(!bool);
  };

  const getinfo = async () => {
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesgetp/yaba`
    ).then((res) => {
      if (res) {
        setdatay(res.data.data);
      }
    });

    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesgetp/abuja`
    ).then((res) => {
      if (res) {
        setdataa(res.data.data);
      }
    });
    // .then(() => {
    //   let y = 0;
    //   dataa.map((res) => {
    //     y = y + res.v_amount;
    //   });
    //   setaa(y);
    // });
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesgetp/lekki`
    ).then((res) => {
      if (res) {
        setdatal(res.data.data);
      }
    });
    // .then(() => {
    //   let y = 0;
    //   datal.map((res) => {
    //     y = y + res.v_amount;
    //   });
    //   setal(y);
    // });
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesgetp/accra`
    ).then((res) => {
      if (res) {
        setdataac(res.data.data);
      }
    });
    // .then(() => {
    //   let y = 0;
    //   dataac.map((res) => {
    //     y = y + res.v_amount;
    //   });
    //   setaac(y);
    // });
  };

  if (datay || dataa || datal || dataac) {
    return (
      <div className="container pr_sfjr9 sv_scrol">
        <div>
          Store Performance
          <span className="pr_jf88">
            - (Click on the bar to view individual performance)
            <IoMdRefreshCircle
              className="pr_jd983"
              data-toggle="tooltip"
              data-placement="top"
              title="Refresh"
              onClick={refresh}
            />
          </span>
        </div>
        <div className=" d-flex">
          <Progress
            color="danger"
            value={(datay / 12000000) * 100}
            className="pr_d88"
          >
            <a href="/performance?store=yaba">
              <div className="pr_d88" style={{ marginLeft: "8px" }}>
                N{datay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </a>
          </Progress>
          <div className="pr_iiee8">
            <div className="pr_dk202"> Yaba Store </div>
            <span className="pr_dk201" style={{ marginLeft: "8px" }}>
              N{datay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </div>
        </div>

        <div className=" d-flex">
          <Progress
            color="primary"
            value={(dataa / 10000000) * 100}
            className="pr_d88"
          >
            <a href="/performance?store=abuja">
              <div className="pr_d88" style={{ marginLeft: "8px" }}>
                N{dataa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </a>
          </Progress>
          <div className="pr_iiee8">
            <div className="pr_dk202"> Abuja Store </div>
            <span className="pr_dk201" style={{ marginLeft: "8px" }}>
              N{dataa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </div>
        </div>
        {/* <div className=" d-flex">
          <Progress
            color="info"
            value={(datal / 10000000) * 100}
            className="pr_d88"
          >
            <a href="/performance?store=lekki">
              <div className="pr_d88" style={{ marginLeft: "8px" }}>
                N{datal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </a>
          </Progress>
          <div className="pr_iiee8">
            <div className="pr_dk202"> lekki Store </div>
            <span className="pr_dk201" style={{ marginLeft: "8px" }}>
              N{datal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </div>
        </div> */}
        <div className=" d-flex">
          <Progress
            color="success"
            value={(dataac / 153846) * 100}
            className="pr_d88"
          >
            <a href="/performance?store=accra">
              <div className="pr_d88" style={{ marginLeft: "8px" }}>
                C{dataac.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </a>
          </Progress>
          <div className="pr_iiee8">
            <div className="pr_dk202"> Accra Store </div>
            <div className="pr_dk201" style={{ marginLeft: "8px" }}>
              C{dataac.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center", fontSize: "12px" }}>
        Performance data unavailable/Data not present
      </div>
    );
  }
};

export default Progressx;
