import React from "react";

const Spinnerxx = (props) => {
  return (
    <div className="spinnerx">
      <div>
        <img src="../../imgs/Blocks.gif" alt="spinner" />
      </div>
    </div>
  );
};

export default Spinnerxx;
