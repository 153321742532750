import React, { useState, useEffect, useContext } from "react";
import { BiReset } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { AppContext } from "../../Context";
import Axios from "axios";
import "./InputStock.css";
import Spinnerxx from "../Utils/Spinnerxx";
const InputStock = (props) => {
  const IPContext = useContext(AppContext);
  Axios.defaults.withCredentials = true;
  const [ss_eng, setss_eng] = useState(0);
  const [g_eng, setg_eng] = useState(0);
  const [ss_bbset, setss_bbset] = useState(0);
  const [st_band, setst_band] = useState(0);
  const [ss_band, setss_band] = useState(0);
  const [t_band, sett_band] = useState(0);
  const [necklace, setnecklace] = useState(0);
  const [v_t_eng, setv_t_eng] = useState(0);
  const [v_g_bands, setv_g_bands] = useState(0);
  const [remarks, setremarks] = useState("");
  const [vvs, setvvs] = useState("hidden");
  const [errm, seterrm] = useState("");

  //////////////////////USE EFFECT /////////////////////
  useEffect(() => {}, []);

  ///////////////      CHANGE HANDLER FOR INPUT    ////////////////
  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (id === "Textarea1") {
      setremarks(value);
    } else {
      if (!isNaN(value)) {
        document.getElementById(id).style.color = "black";
        setvvs("hidden");
        if (value.includes(".")) {
          document.getElementById(id).style.color = "red";
        } else {
          // Action point
          if (id === "ss_eng") {
            setss_eng(parseInt(value));
          } else if (id === "g_eng") {
            setg_eng(parseInt(value));
          } else if (id === "ss_bbset") {
            setss_bbset(parseInt(value));
          } else if (id === "st_band") {
            setst_band(parseInt(value));
          } else if (id === "ss_band") {
            setss_band(parseInt(value));
          } else if (id === "t_band") {
            sett_band(parseInt(value));
          } else if (id === "necklace") {
            setnecklace(parseInt(value));
          } else if (id === "v_t_eng") {
            setv_t_eng(parseInt(value));
          } else if (id === "v_g_bands") {
            setv_g_bands(parseInt(value));
          }
        }
      } else {
        document.getElementById(id).style.color = "red";
        setvvs("visible");
        seterrm("Value must be a Number");
        if (id === "ss_eng") {
          setss_eng(parseInt(0));
        } else if (id === "g_eng") {
          setg_eng(parseInt(0));
        } else if (id === "ss_bbset") {
          setss_bbset(parseInt(0));
        } else if (id === "st_band") {
          setst_band(parseInt(0));
        } else if (id === "ss_band") {
          setss_band(parseInt(0));
        } else if (id === "t_band") {
          sett_band(parseInt(0));
        } else if (id === "necklace") {
          setnecklace(parseInt(0));
        } else if (id === "v_t_eng") {
          setv_t_eng(parseInt(0));
        } else if (id === "v_g_bands") {
          setv_g_bands(parseInt(0));
        }
      }
    }
  };

  //////////////////////// RESET BUTTON//////////////////////////
  const reset = (e) => {
    if (e) {
      e.preventDefault();
    }

    setss_eng(0);
    setg_eng(0);
    setss_bbset(0);
    setst_band(0);
    setss_band(0);
    sett_band(0);
    setnecklace(0);
    setv_t_eng(0);
    setv_g_bands(0);
    setremarks(" ");
  };
  //////////////////////// SAVE BUTTON ///////////////////////////
  const save = (e) => {
    e.preventDefault();
    let [month, date, year] = new Date().toLocaleDateString("en-US").split("/");
    let c_date = `${month}/${date}/${year}`;
    let total =
      ss_eng + g_eng + ss_bbset + st_band + ss_band + t_band + necklace;
    let values = {
      v_ss_eng: parseInt(ss_eng),
      v_g_eng: parseInt(g_eng),
      v_ss_bbset: parseInt(ss_bbset),
      v_st_band: parseInt(st_band),
      v_ss_band: parseInt(ss_band),
      v_t_band: parseInt(t_band),
      v_necklace: parseInt(necklace),
      v_t_eng: parseInt(v_t_eng),
      v_g_bands: parseInt(v_g_bands),
      v_date: c_date,
      v_total: total,
      v_remarks: remarks || "no value",
      v_entryby: IPContext.rrt.fname + " " + IPContext.rrt.lname,
    };

    post(values);

    // console.log(total);
  };

  const post = async (values) => {
    let dd = new Date();
    let month = dd.getMonth() + 1;
    if (month === 13) {
      month = 1;
    }
    await Axios.post(`${process.env.REACT_APP_CLIENT_SERVER}/stock`, {
      v_ss_eng: values.v_ss_eng,
      v_g_eng: values.v_g_eng,
      v_ss_bbset: values.v_ss_bbset,
      v_st_band: values.v_st_band,
      v_ss_band: values.v_ss_band,
      v_t_band: values.v_t_band,
      v_necklace: values.v_necklace,
      v_date: values.v_date,
      v_total: values.v_total,
      v_t_eng: values.v_t_eng,
      v_g_bands: values.v_g_bands,
      v_month: month,
      v_remarks: values.v_remarks,
      v_entryby: values.v_entryby,
      store: IPContext.storeiv,
    })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById("ss_eng").disabled = true;
          document.getElementById("g_eng").disabled = true;
          document.getElementById("ss_bbset").disabled = true;
          document.getElementById("st_band").disabled = true;
          document.getElementById("ss_band").disabled = true;
          document.getElementById("t_band").disabled = true;
          document.getElementById("v_t_eng").disabled = true;
          document.getElementById("v_g_bands").disabled = true;
          document.getElementById("necklace").disabled = true;
          document.getElementById("Textarea1").disabled = true;
          document.getElementById("btn_h").disabled = true;
          document.getElementById("guu3").style.color = "green";
          setvvs("visible");
          seterrm("Data saved!");
          setTimeout(() => {
            setvvs("hidden");
            document.getElementById("guu3").style.color = "red";
            reset();
            document.getElementById("ss_eng").disabled = false;
            document.getElementById("g_eng").disabled = false;
            document.getElementById("ss_bbset").disabled = false;
            document.getElementById("st_band").disabled = false;
            document.getElementById("ss_band").disabled = false;
            document.getElementById("t_band").disabled = false;
            document.getElementById("v_t_eng").disabled = false;
            document.getElementById("v_g_bands").disabled = false;
            document.getElementById("necklace").disabled = false;
            document.getElementById("Textarea1").disabled = false;
            document.getElementById("btn_h").disabled = false;
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /////////////FOCUS //////////////
  const focusx = (e) => {
    e.target.select();
  };

  if (props.userd.username && IPContext.storeiv) {
    return (
      <div
        className={`  sai_main  ${
          IPContext.storeiv === IPContext.rrt.store &&
          IPContext.rrt.role === "sales"
            ? null
            : "disabled"
        }`}
      >
        <div id="accordion">
          <div className="card">
            <div className="card-header si_rwiuevs ux_dhyru23s" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="sales-report-form"
                  data-toggle="collapse"
                  data-target="#ir2"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Stock Report Form
                </button>
              </h5>
            </div>

            <div
              id="ir2"
              className="collapse "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="div_group is_ubiwubes">
                  <div id="guu3" className="msgbar" style={{ visibility: vvs }}>
                    {errm}
                  </div>
                  <form>
                    <div className="reportfor">Report for {props.date}</div>
                    <div className="sales-form">
                      <div className="sales-first-col">
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Sterling Silver Engagement Ring
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ss_eng"
                            onFocus={focusx}
                            value={ss_eng}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Gold Engagement Rings
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            minLength="1"
                            maxLength="4"
                            id="g_eng"
                            onFocus={focusx}
                            value={g_eng}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Stainless Steel Bands
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="st_band"
                            onFocus={focusx}
                            value={st_band}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Silver Bridal Sets </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ss_bbset"
                            onFocus={focusx}
                            value={ss_bbset}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Titanium / Tungsten Bands
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="t_band"
                            onFocus={focusx}
                            value={t_band}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="sales-second-col">
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Titanium Engagement Rings
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="v_t_eng"
                            onFocus={focusx}
                            value={v_t_eng}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Necklaces </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="necklace"
                            onFocus={focusx}
                            value={necklace}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">
                            Sterling Silver Bands
                          </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ss_band"
                            onFocus={focusx}
                            value={ss_band}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Gold Bands</div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="v_g_bands"
                            onFocus={focusx}
                            value={v_g_bands}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="sales-hr" />
                    <div className="sales-form">
                      <div className="sales-first-col">
                        <div className="sales-input-container">
                          <div className="sales-text"> Remarks </div>
                          <textarea
                            className="text_area_stock "
                            id="Textarea1"
                            rows="2"
                            onChange={changeHandler}
                            value={remarks}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="sialert" id="alert"></div>
                    <div className="sialert2" id="alert2"></div>
                    <div className="sales-cta">
                      <div className="sales-reset" id="btn_r" onClick={reset}>
                        <BiReset />
                        Reset
                      </div>
                      <div className="sales-reset" id="btn_h" onClick={save}>
                        <FiSend />
                        Submit
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        {/* <Spinner
          animation="border "
          className="spinnerx2"
          role="status"
          style={{ width: "100px", height: "100px" }}
        ></Spinner> */}
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default InputStock;
