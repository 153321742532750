import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./Salesedit.css";
import { FiSend } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Salesedit = (props) => {
  Axios.defaults.withCredentials = true;
  const [ringCases, setringCases] = useState(0);
  const [carrierBags, setcarrierBags] = useState(0);
  const [ledCases, setledCases] = useState(0);
  const [ringSunggies, setringSunggies] = useState(0);
  const [silverPolish, setsilverPolish] = useState(0);
  const [jewelryCases, setjewelryCases] = useState(0);
  const [jewelryWipes, setjewelryWipes] = useState(0);
  const [ndata, setndata] = useState([]);
  const [salesperson, setsalesperson] = useState();
  const [amount, setamount] = useState(0);
  const [staffDuty, setstaffDuty] = useState();
  const [invoiceNumber, setinvoiceNumber] = useState();
  const [txt, settxt] = useState("");
  const [showtxt, setshowtxt] = useState(false);
  const [newsales, setnewsales] = useState(false);

  useEffect(() => {
    assignn();
    getus();
  }, [props.id]);

  useEffect(() => {
    if (newsales) {
      document.getElementById("ccd").checked = true;
    } else {
      document.getElementById("ccd").checked = false;
    }
  }, [newsales]);

  const assignn = async () => {
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesget/${props.id}/${props.store}`
    )
      .then((res) => {
        setinvoiceNumber(res.data.data.v_invoice);
        setringCases(res.data.data.v_ringCases);
        setcarrierBags(res.data.data.v_carrierBags);
        setledCases(res.data.data.v_ledCases);
        setringSunggies(res.data.data.v_ringSunggies);
        setsilverPolish(res.data.data.v_silverPolish);
        setjewelryCases(res.data.data.v_jewelryCases);
        setjewelryWipes(res.data.data.v_jewelryWipes);
        setstaffDuty(res.data.data.v_staffDuty);
        setsalesperson(res.data.data.v_salesperson);
        setamount(res.data.data.v_amount);
        setnewsales(res.data.data.v_newsales);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  const getus = async () => {
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/getstaff/${props.store}`
    ).then((res) => {
      if (res) {
        setndata(res.data);
      }
    });
  };

  const submit = async (e) => {
    let dd = new Date();
    let ddt = dd.getMonth() + 1 + "/" + dd.getDate() + "/" + dd.getFullYear();
    e.preventDefault();
    await Axios.put(`${process.env.REACT_APP_CLIENT_SERVER}/salesedit`, {
      invoiceNumber: invoiceNumber,
      ringCases: ringCases,
      carrierBags: carrierBags,
      ringSunggies: ringSunggies,
      silverPolish: silverPolish,
      jewelryCases: jewelryCases,
      jewelryWipes: jewelryWipes,
      ledCases: ledCases,
      staffDuty: staffDuty,
      id: props.id,
      entryby: props.data.fname + " " + props.data.lname,
      store: props.store,
      salesperson: salesperson,
      amount: amount,
      v_month: dd.getMonth() + 1,
      v_newsales: newsales,
    }).then((res) => {
      if (res.status === 200) {
        props.cancel();
        props.refresh(ddt);
      }
    });
  };

  const checkccd = (e) => {
    setnewsales(!newsales);
  };
  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (!isNaN(value)) {
      setshowtxt(false);
      switch (id) {
        case "ringCases":
          setringCases(value);
          break;
        case "carrierBags":
          setcarrierBags(value);
          break;
        case "ringSunggies":
          setringSunggies(value);
          break;
        case "silverPolish":
          setsilverPolish(value);
          break;
        case "jewelryCases":
          setjewelryCases(value);
          break;
        case "jewelryWipes":
          setjewelryWipes(value);
          break;
        case "ledCases":
          setledCases(value);
          break;
        case "amount":
          setamount(value);
          break;

        default:
          break;
      }
    } else {
      if (id === "invoiceNumber") {
        setinvoiceNumber(value);
      } else if (id === "staffDuty") {
        setstaffDuty(value);
      } else if (id === "salesperson") {
        setsalesperson(value);
      } else {
        settxt("Values must be numbers only");
        setshowtxt(true);
      }
    }
  };

  return (
    <div className="sales_edit_form">
      <div className="sales-form-edit">
        <div className="sales-first-col">
          <div className="sales-input-container">
            <div className="sales-text"> Invoice# </div>
            <input
              type="text"
              className="sales-input"
              value={invoiceNumber}
              id="invoiceNumber"
              onChange={changeHandler}
              style={{
                height: "32px",
                borderStyle: "solid",
                background: "rgba(255,255,255,0);",
              }}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Invoice Amount </div>
            <input
              type="number"
              className="sales-input"
              value={amount}
              id="amount"
              onChange={changeHandler}
              style={{
                height: "32px",
                borderStyle: "solid",
                background: "rgba(255,255,255,0);",
              }}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Ring Box(es) </div>
            <input
              type="number"
              className="sales-input"
              value={ringCases}
              id="ringCases"
              onChange={changeHandler}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Carrier Bag(s) </div>
            <input
              type="number"
              className="sales-input"
              value={carrierBags}
              id="carrierBags"
              onChange={changeHandler}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Led Box(es) </div>
            <input
              type="number"
              className="sales-input"
              value={ledCases}
              id="ledCases"
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="sales-second-col">
          <div className="sales-input-container">
            <div className="sales-text"> Silver Wipe(s) </div>
            <input
              type="number"
              className="sales-input"
              value={silverPolish}
              id="silverPolish"
              onChange={changeHandler}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Jewelry Box(es) </div>
            <input
              type="number"
              className="sales-input"
              value={jewelryCases}
              id="jewelryCases"
              onChange={changeHandler}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Jewelry Wipe(s) </div>
            <input
              type="number"
              className="sales-input"
              value={jewelryWipes}
              id="jewelryWipes"
              onChange={changeHandler}
            />
          </div>
          <div className="sales-input-container">
            <div className="sales-text"> Ring Snuggie(s) </div>
            <input
              type="number"
              className="sales-input"
              value={ringSunggies}
              id="ringSunggies"
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      <hr className="sales-hr" />
      <div className="sales-form-edit">
        <div className="sales-first-col">
          <div className="sales-input-container">
            <div className="sales-text"> Sales Person </div>
            <select
              name="cars"
              id="salesperson"
              className="sales-person-dropdown"
              onChange={changeHandler}
            >
              {/* <option
            value="delphi"
            className="sales-person-option"
          >
            Delphi
          </option> */}

              {ndata.map((res, i) => {
                return (
                  <option
                    value={res}
                    key={i}
                    className="sales-person-option"
                    selected={res === salesperson ? "selected" : ""}
                  >
                    {res}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="sales-input-container">
            <div className="sales-checkbox">
              <input
                type="checkbox"
                className="sales-checkbox-input"
                id="ccd"
                onClick={checkccd}
              />
              New invoice
            </div>
          </div>
        </div>
        <div className="sales-second-col">
          <div className="sales-input-container">
            <div className="sales-text">Staff on duty </div>
            <input
              type="text"
              className="sales-input"
              value={staffDuty}
              id="staffDuty"
              onChange={changeHandler}
              style={{
                height: "32px",
                borderStyle: "solid",
                background: "rgba(255,255,255,0);",
              }}
            />
          </div>
        </div>
      </div>
      <div className="sialert" id="alert"></div>
      <div className="sales-cta">
        <div className="sales-reset" id="btn_h" onClick={props.cancel}>
          <AiOutlineCloseCircle /> Close
        </div>
        <div className="sales-reset" id="btn_h" onClick={submit}>
          <FiSend /> Submit
        </div>
      </div>
    </div>
  );
};

export default Salesedit;
