import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./pcdelete.css";

const Pcdelete = (props) => {
  const [data, setdata] = useState({});

  useEffect(() => {
    setdata(props.data);
  }, []);

  const deleteop = async () => {
    let dt = new Date();
    let dd = dt.getMonth() + 1;
    await Axios.post(
      `${process.env.REACT_APP_CLIENT_SERVER}/pettydelete/${props.store}`,
      {
        id: data[0]._id,
        dd: dd,
      }
    ).then((res) => {
      props.clear();
      props.ss();
    });
  };

  if (props.data) {
    return (
      <div className="pcd_main slide-top">
        <div className="pcd_txt">
          The selected entry will be deleted and cannot be retrieved later. Are
          you sure about deleting it?
        </div>
        <div>
          <div className="pcd_btn">
            <button type="button" className="petty_submit" onClick={deleteop}>
              Yes
            </button>
            <button
              type="button"
              className="petty_close"
              onClick={(e) => {
                e.preventDefault();
                props.clear();
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default Pcdelete;
