import { useState, useEffect, useCallback } from "react";
import Axios from "axios";

const useGetInvoices = (limit, page) => {
  const [data, setData] = useState();

  const fetchInvoices = useCallback(async () => {
    return await Axios.post(
      `${process.env.REACT_APP_SERVER}/getinvoicesql`,
      {
        query: `
            query GetInvoices($params: InvoicesParams) {
                getInvoices(params: $params) {
                  total
                  data {
                    branchName
                    invoiceNumber
                    invoiceDate
                    itemDesc
                    total
                    cfReferral
                    cfStatus
                    currencyCode
                    customerName
                    email
                    invoiceStatus
                    itemName
                    sku
                    adjustment
                    balance
                    discountAmount
                    discountPercent
                    itemTax
                    lastPaymentDate
                    phone
                    quantity
                    shippingAddress
                    subTotal
                    taxAmount
                    taxInclusive
                    taxPercent
                  } 
                }
              }
          
              
            `,
        variables: {
          params: {
            limit,
            page,
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [limit, page]);

  useEffect(() => {
    fetchInvoices().then((res) => {
      if (res) {
        const tempData = res.data.data.getInvoices;
        const newData = {
          ...tempData,
          data: tempData?.data.map((item, i) => {
            return {
              ...item,
              id: i,
            };
          }),
        };
        setData(newData);
      }
    });
  }, [fetchInvoices]);

  return {
    data,
  };
};

export default useGetInvoices;
