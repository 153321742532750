import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { AppContext } from "../../Context";

import ButtonDropdown from "../Utils/ButtonDropdown";
import Axios from "axios";
import "./Pettyviewer.css";
import Spinnerxx from "../Utils/Spinnerxx";
import YearDropdown from "../Utils/YearDropdown";

const Pettyviewer = (props) => {
  const PContext = useContext(AppContext);
  const [ddname, setddname] = useState("Select Month");
  const [data1, setdata] = useState({});
  const [monthx, setMonthx] = useState(1);

  const [year, setYear] = useState(new Date().getFullYear().toString());

  let crr = 0;
  let drr = 0;

  const ty = useCallback(async () => {
    // effect;
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/pettycashmain/${monthx}/${year}/${PContext.storeiv}`
    )
      .then((res) => {
        setdata(res.data);
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  }, [PContext.storeiv, monthx, year]);

  const monthNames = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  useEffect(() => {
    let dd = new Date();
    let month = dd.getMonth() + 1;
    if (month === 13) {
      month = 1;
    }
    setMonthx(month);
    setddname(monthNames[dd.getMonth()]);
  }, [monthNames]);

  useEffect(() => {
    ty();
  }, [ty]);

  const numberWithCommas = (x) => {
    x = String(x);
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const jump = (e) => {
    e.preventDefault();
    let id = parseInt(e.target.id);
    setMonthx(id);
    if (parseInt(id) === 1) {
      setddname("January");
    } else if (id === 2) {
      setddname("February");
    } else if (id === 3) {
      setddname("March");
    } else if (id === 4) {
      setddname("April");
    } else if (id === 5) {
      setddname("May");
    } else if (id === 6) {
      setddname("June");
    } else if (id === 7) {
      setddname("July");
    } else if (id === 8) {
      setddname("August");
    } else if (id === 9) {
      setddname("September");
    } else if (id === 10) {
      setddname("October");
    } else if (id === 11) {
      setddname("November");
    } else if (id === 12) {
      setddname("December");
    }
  };

  if (props.userd.username && PContext.storeiv) {
    return (
      <div className="container sv_scrol">
        <div id="accordion">
          <div className="card">
            <div className="card-header si_rwiuevs ux_dhyru23s" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="sales-report-form"
                  data-toggle="collapse"
                  data-target="#pv"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Petty Cash Viewer - {PContext.storeiv.toUpperCase()}
                </button>
              </h5>
            </div>

            <div
              id="pv"
              className="collapse "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <div className="pv_flex">
                          <div className=" pt_wue8r9a"></div>
                          <div className="pt_jiwue ">
                            <ButtonDropdown ddname={ddname} jump={jump} />
                          </div>
                          <YearDropdown setYearx={setYear} />
                        </div>
                      </div>
                    </div>
                    {data1.data ? (
                      <div className="row">
                        <div className="col">
                          <div>
                            <table
                              style={{ fontSize: "12px" }}
                              className="table table-striped table-bordered pv_txt"
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">CR</th>
                                  <th scope="col">DR</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Edited</th>
                                  <th scope="col">Entry By</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data1.data.reverse().map((res) => {
                                  crr = crr + res.cr;
                                  drr = drr + res.dr;
                                  return (
                                    <tr key={res._id}>
                                      <th scope="row">{res.date}</th>
                                      <td>{numberWithCommas(res.cr)}</td>
                                      <td>{numberWithCommas(res.dr)}</td>
                                      <td>{res.description}</td>
                                      <td>{res.edate}</td>
                                      <td>{res.v_entryby}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th scope="row">Total</th>
                                  <th scope="row"> {numberWithCommas(crr)}</th>
                                  <th scope="row">{numberWithCommas(drr)}</th>
                                </tr>
                                <tr>
                                  <th scope="row">Balance Carried Forward</th>
                                  <th scope="row">
                                    {numberWithCommas(crr - drr)}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div> Loading</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default Pettyviewer;
