import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
  const [dd, setdd] = useState(new Date());

  return <div className="ft_jfie">© {dd.getFullYear()}, Delphi Metals Web</div>;
};

export default Footer;
