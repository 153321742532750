import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./Pettyedit.css";

const Pettyedit = (props) => {
  const [editamount, seteditamount] = useState(0);
  const [editdes, seteditdes] = useState("");
  const [editcr, seteditcr] = useState(false);
  const [editdr, seteditdr] = useState(false);

  useEffect(() => {
    seteditamount(props.data[0].dr > 0 ? props.data[0].dr : props.data[0].cr);
    seteditdes(props.data[0].description);
    if (props.data[0].dr > 0) {
      seteditdr(true);
      seteditcr(false);
      document.getElementById("rd1").checked = true;
    } else if (props.data[0].cr > 0) {
      seteditdr(false);
      seteditcr(true);
      document.getElementById("rd2").checked = true;
    }
  }, [props]);

  const submitData = async (e) => {
    if (isNaN(editamount)) {
    } else {
      e.preventDefault();
      let dd = new Date();
      let dd2 = dd.getMonth() + 1;
      await Axios.post(
        `${process.env.REACT_APP_CLIENT_SERVER}/pettycashpost/${props.store}`,
        {
          amount: editamount,
          des: editdes,
          cr: editcr,
          dr: editdr,
          dd: dd2,
          id: props.data[0]._id,
          v_entryby: props.datar.fname + " " + props.datar.lname,
        }
      ).then((res) => {
        props.clear();
        props.ss();
      });
    }
  };

  const editChanger = (e) => {
    if (e.target.id === "pc_in_ed") {
      seteditamount(e.target.value);
    } else if (e.target.id === "pc_in_ed2") {
      seteditdes(e.target.value);
    } else if (e.target.id === "rd1") {
      seteditdr(true);
      seteditcr(false);
    } else if (e.target.id === "rd2") {
      seteditcr(true);
      seteditdr(false);
    }
  };

  return (
    <div className="pc_edit slide-top">
      <div className="pc_edd"> Edit</div>
      <div className="pc_form">
        <div className="pc_ed_div">
          <div className="pc_ed_lb">
            <label htmlFor="pc_in_ed"> Amount: </label>
          </div>
          <div>
            <input
              type="text"
              id="pc_in_ed"
              className="pc_text"
              placeholder={
                props.data[0].dr > 0 ? props.data[0].dr : props.data[0].cr
              }
              onChange={editChanger}
              value={editamount}
            />
          </div>
        </div>
        <div className="pc_ed_div">
          <div className="pc_ed_lb">
            <label htmlFor="pc_in_ed2"> Description: </label>
          </div>
          <div>
            <input
              type="text"
              id="pc_in_ed2"
              className="pc_text2"
              placeholder={props.data[0].description}
              onChange={editChanger}
              value={editdes}
            />
          </div>
        </div>
        <div className=" pc_ed_div ">
          <div className="pc_ed_sel">
            <input
              className="form-check-input pc_ed_sel2"
              type="radio"
              name="selection"
              id="rd1"
              //   checked={props.data[0].dr > 0 ? "checked" : null}
              onChange={editChanger}
            />
            <label className="form-check-label pc_ed_sel" htmlFor="rd1">
              Disburse
            </label>
          </div>
          <div>
            <input
              className="form-check-input pc_ed_sel2"
              type="radio"
              name="selection"
              id="rd2"
              //   checked={props.data[0].cr > 0 ? "checked" : null}
              onChange={editChanger}
            />
            <label className="form-check-label pc_ed_sel" htmlFor="rd2">
              Reimburse
            </label>
          </div>
        </div>

        <div className="pc_ed_btn">
          <button className="petty_submit" onClick={submitData}>
            Submit
          </button>
          <button
            className="petty_close"
            onClick={() => {
              props.clear();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pettyedit;
