import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { message } from "antd";

import Axios from "axios";
import {
  ButtonFormPane,
  DatePickerC,
  FormBtnWrapper,
  FormWrapper,
  InputWrapper2,
  InputWrapper2b,
  NewOrderContainer,
  NewOrderInput,
  NewOrderSelect,
  TextAreaC,
} from "../styles";

export const options = [
  {
    value: "ordering",
    label: "Ordering",
  },
  {
    value: "production",
    label: "Production",
  },
  {
    value: "shippingstore",
    label: "Shipping to Store",
  },
  {
    value: "arrived",
    label: "Arrived Nigeria",
  },
  {
    value: "ready",
    label: "Ready for Pickup/Delivery",
  },
  {
    value: "shippingcutomer",
    label: "Shipped to Customer",
  },
  {
    value: "pickedup",
    label: "Picked Up",
  },
  {
    value: "delivered",
    label: "Delivered",
  },
];
const NewOrder = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [lname, setlname] = useState("");
  const [status, setstatus] = useState("Ordering");
  const [edd, setedd] = useState("");
  const [email, setemail] = useState("");
  const [description, setdesription] = useState("");
  const [resst, setresst] = useState("");
  const [resmsg, setresmsg] = useState("");
  const [err, seterr] = useState(false);
  const [errmsg, seterrmsg] = useState("");

  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    switch (id) {
      case "invoiceNumber":
        setinvoiceNumber(value);
        break;
      case "lname":
        setlname(value);
        break;
      case "email":
        setemail(value);
        break;
      case "description":
        setdesription(value);
        break;

      default:
        break;
    }
  };

  const success = (succ) => {
    messageApi.open({
      type: "success",
      content: succ,
      style: {
        marginTop: "18vh",
      },
    });
  };
  const error = (err) => {
    messageApi.open({
      type: "error",
      content: err,
      style: {
        marginTop: "18vh",
      },
    });
  };

  const resett = () => {
    setinvoiceNumber("");
    setlname("");
    setedd("");
    setstatus("Ordering");
    setemail("");
    setdesription("");
  };

  const checkValues = () => {
    if (EmailValidator.validate(email.toLowerCase())) {
      if (edd) {
        postdata();
      } else {
        seterr(true);
        error("Error with EDD, check and correct it");
        seterrmsg("Error with EDD, check and correct it");
      }
    } else {
      seterr(true);
      error("Error with Email, check and correct it");
      seterrmsg("Error with Email, check and correct it");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    checkValues();
  };

  const postdata = async () => {
    let dd = new Date();
    let ddm =
      dd.getDate() +
      "/" +
      (dd.getMonth() + 1) +
      "/" +
      dd.getFullYear() +
      "  " +
      dd.getHours() +
      ":" +
      dd.getMinutes() +
      "GMT";
    seterr(false);
    let orderid = invoiceNumber + lname;
    orderid = orderid.replace(/\s/g, "");
    let values = {
      orderId: orderid,
      invoiceNumber: invoiceNumber,
      lname: lname,
      status: status,
      edd: edd,
      email: email,
      description: description,
      date: ddm,
      dateReal: dd,
      addedby: props.mored.fname + " " + props.mored.lname,
    };

    Axios.post(`${process.env.REACT_APP_SERVER}/postnew`, values).then(
      (res) => {
        if (res.data.status === 200) {
          setresst(res.data.status);
          setresmsg(res.data.msg);
          success(res.data.msg);
          resett();
        } else {
          setresst(res.data.status);
          setresmsg(res.data.msg);
          error(res.data.msg);
        }
      }
    );
  };

  return (
    <div className="">
      <NewOrderContainer>
        <FormWrapper>
          {contextHolder}
          <InputWrapper2>
            <NewOrderInput
              placeholder="Invoice Number"
              id="invoiceNumber"
              value={invoiceNumber}
              onChange={changeHandler}
            />
          </InputWrapper2>
          <InputWrapper2>
            <NewOrderInput
              placeholder="Last Name"
              id="lname"
              value={lname}
              onChange={changeHandler}
            />
          </InputWrapper2>
        </FormWrapper>
        <FormWrapper>
          <InputWrapper2b>
            <NewOrderSelect
              options={options}
              defaultValue="ordering"
              size="large"
              id="status"
              value={status}
              onChange={(value) => setstatus(value)}
            />
            <DatePickerC
              size="large"
              onChange={(value) =>
                setedd(`${value.date()}/${value.month() + 1}/${value.year()}`)
              }
            />
          </InputWrapper2b>

          <InputWrapper2b>
            <NewOrderInput
              placeholder="Email"
              id="email"
              value={email}
              onChange={changeHandler}
            />
          </InputWrapper2b>
        </FormWrapper>
        <FormWrapper>
          <TextAreaC
            rows={4}
            placeholder="Description"
            id="description"
            value={description}
            onChange={changeHandler}
          />
        </FormWrapper>
        <FormBtnWrapper>
          <ButtonFormPane onClick={submitHandler}> Submit </ButtonFormPane>
        </FormBtnWrapper>
      </NewOrderContainer>
    </div>
  );
};

export default NewOrder;
