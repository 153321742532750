import Axios from "axios";
import React, { useState, useEffect } from "react";
import "./ChangePassword.css";

const ChangePassword = (props) => {
  const [oldp, setoldp] = useState("");
  const [newp, setnewp] = useState("");
  const [newp2, setnewp2] = useState("");
  const [errm, seterrm] = useState(false);
  const [txtm, settxtm] = useState("");
  const [col, setcol] = useState("red");

  useEffect(() => {}, []);

  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "oldp":
        setoldp(value);
        break;
      case "newp":
        setnewp(value);
        break;
      case "newp2":
        setnewp2(value);
        break;

      default:
        break;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (newp !== newp2) {
      seterrm(true);
      settxtm("Passwords do not match!");
    } else {
      pChanger();
    }
  };

  const pChanger = async () => {
    await Axios.post(`${process.env.REACT_APP_CLIENT_SERVER}/pchange`, {
      oldpassword: oldp,
      newpassword: newp,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setcol("rgb(97, 145, 97)");
          settxtm(res.data.data);
          setnewp("");
          setoldp("");
          setnewp2("");
          seterrm(true);
        } else if (res.data.status === 404) {
          settxtm(res.data.data);
          setnewp("");
          setoldp("");
          setnewp2("");
          seterrm(true);
        } else {
          settxtm("Error");
          setnewp("");
          setoldp("");
          setnewp2("");
          seterrm(true);
        }
      })
      .catch((e) => {
        if (e) {
        }
      });
  };

  return (
    <div>
      <div className="   main_container">Change your password</div>
      <div className="container  ">
        <div
          className="se_divccs"
          style={{
            marginTop: "18px",
            paddingTop: "23px",
            paddingBottom: "50px",
          }}
        >
          {errm && (
            <div className="cp_weuri2" style={{ color: col }}>
              {txtm}
            </div>
          )}

          <div className="d-lg-flex d-xl-flex justify-content-md-center justify-content-lg-center justify-content-xl-center se_divgroup">
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="change_pwd_line">
                  <label
                    className="chpwd_label"
                    style={{
                      width: "200px",
                      marginRight: "7",
                      marginLeft: "0px",
                    }}
                  >
                    Current Password:
                  </label>
                  <input
                    type="password"
                    className="chpwd_input"
                    id="oldp"
                    onChange={changeHandler}
                    value={oldp}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-flex d-xl-flex justify-content-md-center justify-content-lg-center justify-content-xl-center se_divgroup">
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="change_pwd_line">
                  <label
                    className="chpwd_label"
                    style={{
                      width: "200px",
                      marginRight: "7",
                      marginLeft: "0px",
                    }}
                  >
                    New Password:
                  </label>
                  <input
                    type="password"
                    className="chpwd_input"
                    id="newp"
                    onChange={changeHandler}
                    value={newp}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-flex d-xl-flex justify-content-md-center justify-content-lg-center justify-content-xl-center se_divgroup">
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="change_pwd_line">
                  <label
                    className="chpwd_label"
                    style={{
                      width: "200px",
                      marginRight: "7",
                      marginLeft: "0px",
                    }}
                  >
                    Retype New Password:
                  </label>
                  <input
                    type="password"
                    className="chpwd_input"
                    id="newp2"
                    value={newp2}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="se_btt2 cp_jdjh883">
            <button
              className="chpwd_submit"
              type="button"
              onClick={submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
