import React, { useState, useEffect } from "react";

const YearDropdown = ({ setYearx }) => {
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    setYearx(year);
  }, [year, setYearx]);

  return (
    <select
      defaultValue={year}
      className="select_year_vs"
      onClick={(e) => setYear(e.currentTarget.value)}
    >
      <option value={2021}> 2021</option>
      <option value={2022}> 2022</option>
      <option value={2023}> 2023</option>
    </select>
  );
};

export default YearDropdown;
