import React from "react";
import { DataGrid } from "@mui/x-data-grid";

import { TableHeaders, TrackTableContainer } from "./styles";
import Loading from "../../components/Loading";

const TrackTable = ({ data }) => {
  const rows = data?.map((d, i) => {
    return {
      key: i + 1,
      id: i,
      invoiceNumber: d.invoiceNumber,
      status: d.status,
      date: d.date,
      edd: d.edd,
      description: d.description,
    };
  });

  const columnsX = [
    {
      flex: 0.5,
      field: "invoiceNumber",
      headerName: <TableHeaders> Invoice Number</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "status",
      headerName: <TableHeaders> Status</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "date",
      headerName: <TableHeaders> Date</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "edd",
      headerName: <TableHeaders> Expected Delivery Date</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "description",
      headerName: <TableHeaders> Description</TableHeaders>,
    },
  ];
  if (data) {
    return (
      <TrackTableContainer>
        <DataGrid
          style={{
            fontSize: "12px",
          }}
          rows={rows}
          columns={columnsX}
          pagination={undefined}
          hideFooterPagination
        />
      </TrackTableContainer>
    );
  }
  return <Loading />;
};

export default TrackTable;
