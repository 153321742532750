import React, { useState } from "react";
import "./index.style.css";

const Input = ({ readUploadFile }) => {
  const [data, setData] = useState("");
  return (
    <input
      type="file"
      name="upload"
      id="upload"
      accept=".xls,.xlsx"
      onChange={(e) => {
        readUploadFile(e);
      }}
    />
  );
};

export default Input;
