import React from "react";
import { Spinner } from "react-bootstrap";
import Spinnerxx from "../Utils/Spinnerxx";
import "./Profile.css";

const Profile = (props) => {
  if (props.userd.username) {
    return (
      <div>
        <div className="container ">
          <div>
            <form>
              <div className="form-group">
                <div id="formdiv">
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>First Name:</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <input
                        type="text"
                        className="form-control pf_disable"
                        style={{
                          marginLeft: "0px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                        name="name"
                        placeholder="Name"
                        value={props.data.fname}
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Last Name:</strong>
                      </p>
                    </div>
                    <div
                      className="col-md-10 offset-md-1"
                      style={{ fontFamily: "Roboto, sans-serif" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ marginLeft: "0px" }}
                        placeholder="Name"
                        disabled
                        value={props.data.lname}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Email Address:</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <input
                        type="text"
                        className="form-control"
                        style={{
                          marginLeft: "0px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                        placeholder="Name"
                        disabled
                        value={props.data.email}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Store:</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <textarea
                        className="form-control"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                        placeholder="Address"
                        disabled
                        value={props.data.store}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Role:</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <textarea
                        className="form-control"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                        placeholder="Address"
                        disabled
                        value={props.data.role}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Staff Number:</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <input
                        type="number"
                        className="form-control"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                        placeholder="Number"
                        disabled
                        value={props.data.staffnumber}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  >
                    <div className="col-md-8 offset-md-1">
                      <p
                        style={{
                          marginLeft: "2%",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        <strong>Last Login</strong>
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1">
                      <textarea
                        className="form-control"
                        style={{
                          fontFamily: "Roboto, sans-serif",
                        }}
                        placeholder="Remarks"
                        disabled
                        value={props.data.lastlogin}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{
                      marginRight: "0px;",
                      marginLeft: "0px",
                      paddingTop: "24px",
                    }}
                  ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default Profile;
