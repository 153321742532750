import React, { useState, useEffect } from "react";
import Axios from "axios";
import EditOrders from "./EditOrders";
import "./UpdateOrders.css";
import {
  ButtonFormPane,
  InputFormPane,
  StatusButton,
  TableHeaders,
  TrackTableContainer,
  UpdateHeaderWrapper,
} from "../styles";
import { DataGrid } from "@mui/x-data-grid";
import { Modal } from "antd";

const UpdateOrders = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dd, setdd] = useState([{}]);
  const [enableedit, setenableedit] = useState(false);
  const [editv, seteditv] = useState("");
  const [ref, setref] = useState(false);
  const [searchx, setsearchx] = useState("");

  useEffect(() => {
    fetchData();
  }, [ref]);

  const refreshx = () => {
    setref(!ref);
  };

  const fetchData = async () => {
    await Axios.get(`${process.env.REACT_APP_SERVER}/getpendingorders`)
      .then((res) => {
        if (res) {
          setdd(res.data.data);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setenableedit(false);
    setref(!ref);
  };

  const editMe = (x) => {
    setenableedit(true);
    seteditv(x);
  };

  const closeMe = () => {
    setenableedit(false);
    setref(!ref);
  };

  const changeHandler = (e) => {
    let value = e.target.value;
    setsearchx(value.toUpperCase());
  };

  const submitHandler = (e) => {
    e.preventDefault();
    searchy();
  };

  const searchy = async () => {
    await Axios.post(`${process.env.REACT_APP_SERVER}/searchporder`, {
      invoiceNumber: searchx,
    }).then((res) => {
      if (res) {
        setdd(res.data.data);
      }
    });
  };

  const tableData = dd?.map((td, i) => {
    return {
      ...td,
      id: i,
    };
  });

  const columns = [
    {
      flex: 0.5,
      field: "invoiceNumber",
      headerName: <TableHeaders> Invoice Number</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "lname",
      headerName: <TableHeaders> Last Name</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "status",
      headerName: <TableHeaders> Status</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "Action",
      headerName: <TableHeaders> Action</TableHeaders>,
      renderCell: ({ row }) => {
        return (
          <StatusButton onClick={() => editMe(row.invoiceNumber)}>
            {" "}
            Update{" "}
          </StatusButton>
        );
      },
    },
  ];

  return (
    <div>
      <UpdateHeaderWrapper>
        <InputFormPane
          placeholder="Input invoice number"
          id="sdjiwe"
          onChange={changeHandler}
        />
        <ButtonFormPane onClick={submitHandler}> Search </ButtonFormPane>
      </UpdateHeaderWrapper>
      <TrackTableContainer height="700px">
        <Modal
          title="Update Order"
          open={enableedit}
          onOk={handleOk}
          onCancel={handleCancel}
          width="900px"
          footer={null}
          style={{
            top: "150px",
          }}
        >
          <EditOrders data={editv} close={handleCancel} key={Date.now()} />
        </Modal>
        <DataGrid
          columns={columns}
          rows={tableData}
          pageSize={10}
          hideFooterSelectedRowCount
        />
      </TrackTableContainer>
    </div>
  );
};

export default UpdateOrders;
