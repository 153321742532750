import React, { useState, useEffect, useCallback, useRef } from "react";

import jspdf from "jspdf";
import ReactToPrint from "react-to-print";
import ReactDomServer from "react-dom/server";
import FormX from "../Components/Form";
import "./MainPage.style.css";

const MainPage = () => {
  const doc = new jspdf({
    orientation: "p",
    unit: "px",
  });
  const TRef = useRef();
  const [yaba, setYaba] = useState("");
  const [abuja, setAbuja] = useState("");
  const [lekki, setLekki] = useState("");
  const [yabaTo, setYabaTo] = useState("");
  const [abujaTo, setAbujaTo] = useState("");
  const [lekkiTo, setLekkiTo] = useState("");
  const [sum, setSum] = useState();
  const [currentSelection, setCurrentSelection] = useState("");
  const [selector, setSelector] = useState(1);

  const YabaOut = useCallback(() => {
    const Yaba = [];
    const codesInStore = Object.keys(yaba);

    codesInStore.forEach((res) => {
      let obj1 = {};
      let obj2 = {};
      if (yaba[res] > lekki[res] || yaba[res] > abuja[res]) {
        if (
          yaba[res] > parseInt(lekki[res]) * 2 &&
          yaba[res] > parseInt(abuja[res]) * 2
        ) {
          if (yaba[res] === 1 || yaba[res] === 2) {
            //nothing should happen
          } else {
            // divide yaba's stock into two and share equally with Abuja and Lekki
            if (yaba[res] === 3) {
              const share = 1;
              obj1["code"] = res;
              obj1["from"] = "Yaba";
              obj1["to"] = "Lekki";
              obj1["quantity"] = share;
              Yaba.push(obj1);
            } else {
              const share = Math.floor(yaba[res] / 3);
              obj1["code"] = res;
              obj1["from"] = "Yaba";
              obj1["to"] = "Lekki";
              obj1["quantity"] = share;
              obj2["code"] = res;
              obj2["from"] = "Yaba";
              obj2["to"] = "Abuja";
              obj2["quantity"] = share;

              Yaba.push(obj2);
              Yaba.push(obj1);
            }
          }
        } else if (
          yaba[res] > parseInt(lekki[res]) * 2 &&
          yaba[res] < parseInt(abuja[res]) * 2
        ) {
          if (yaba[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to lekki
            const share = Math.floor(yaba[res] / 2);
            obj1["code"] = res;
            obj1["from"] = "Yaba";
            obj1["to"] = "Lekki";
            obj1["quantity"] = share;
            Yaba.push(obj1);
          }
        } else if (
          yaba[res] < parseInt(lekki[res]) * 2 &&
          yaba[res] > parseInt(abuja[res]) * 2
        ) {
          if (yaba[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to Abuja
            const share = Math.floor(yaba[res] / 2);
            obj2["code"] = res;
            obj2["from"] = "Yaba";
            obj2["to"] = "Abuja";
            obj2["quantity"] = share;
            Yaba.push(obj2);
          }
        }
      } else {
        //skip
      }
    });
    Yaba.sort((a, b) => (a.to > b.to ? 1 : b.to > a.to ? -1 : 0));
    setYabaTo(Yaba);
  }, [abuja, lekki, yaba]);

  const LekkiOut = useCallback(() => {
    const Lekki = [];
    const codesInStore = Object.keys(lekki);

    codesInStore.forEach((res) => {
      let obj1 = {};
      let obj2 = {};
      if (lekki[res] > yaba[res] || lekki[res] > abuja[res]) {
        if (
          lekki[res] > parseInt(yaba[res]) * 2 &&
          lekki[res] > parseInt(abuja[res]) * 2
        ) {
          if (lekki[res] === 1 || lekki[res] === 2) {
            //nothing should happen
          } else {
            // divide yaba's stock into two and share equally with Abuja and Lekki
            if (lekki[res] === 3) {
              const share = 1;
              obj1["code"] = res;
              obj1["from"] = "Lekki";
              obj1["to"] = "Yaba";
              obj1["quantity"] = share;
              Lekki.push(obj1);
            } else {
              const share = Math.floor(lekki[res] / 3);
              obj1["code"] = res;
              obj1["from"] = "Lekki";
              obj1["to"] = "Yaba";
              obj1["quantity"] = share;
              obj2["code"] = res;
              obj2["from"] = "Lekki";
              obj2["to"] = "Abuja";
              obj2["quantity"] = share;

              Lekki.push(obj2);
              Lekki.push(obj1);
            }
          }
        } else if (
          lekki[res] > parseInt(yaba[res]) * 2 &&
          lekki[res] < parseInt(abuja[res]) * 2
        ) {
          if (lekki[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to lekki
            const share = Math.floor(lekki[res] / 2);
            obj1["code"] = res;
            obj1["from"] = "Lekki";
            obj1["to"] = "Yaba";
            obj1["quantity"] = share;
            Lekki.push(obj1);
          }
        } else if (
          lekki[res] < parseInt(yaba[res]) * 2 &&
          lekki[res] > parseInt(abuja[res]) * 2
        ) {
          if (lekki[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to Abuja
            const share = Math.floor(lekki[res] / 2);
            obj2["code"] = res;
            obj2["from"] = "Lekki";
            obj2["to"] = "Abuja";
            obj2["quantity"] = share;
            Lekki.push(obj2);
          }
        }
      } else {
        //skip
      }
    });
    Lekki.sort((a, b) => (a.to > b.to ? 1 : b.to > a.to ? -1 : 0));
    setLekkiTo(Lekki);
  }, [abuja, lekki, yaba]);

  const AbujaOut = useCallback(() => {
    const Abuja = [];
    const codesInStore = Object.keys(lekki);

    codesInStore.forEach((res) => {
      let obj1 = {};
      let obj2 = {};
      if (abuja[res] > yaba[res] || abuja[res] > lekki[res]) {
        if (
          abuja[res] > parseInt(yaba[res]) * 2 &&
          abuja[res] > parseInt(lekki[res]) * 2
        ) {
          if (abuja[res] === 1 || abuja[res] === 2) {
            //nothing should happen
          } else {
            // divide yaba's stock into two and share equally with Abuja and Lekki
            if (abuja[res] === 3) {
              const share = 1;
              obj1["code"] = res;
              obj1["from"] = "Abuja";
              obj1["to"] = "Yaba";
              obj1["quantity"] = share;
              Abuja.push(obj1);
            } else {
              const share = Math.floor(abuja[res] / 3);
              obj1["code"] = res;
              obj1["from"] = "Abuja";
              obj1["to"] = "Yaba";
              obj1["quantity"] = share;
              obj2["code"] = res;
              obj2["from"] = "Abuja";
              obj2["to"] = "Lekki";
              obj2["quantity"] = share;

              Abuja.push(obj2);
              Abuja.push(obj1);
            }
          }
        } else if (
          abuja[res] > parseInt(yaba[res]) * 2 &&
          abuja[res] < parseInt(lekki[res]) * 2
        ) {
          if (abuja[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to abuja
            const share = Math.floor(abuja[res] / 2);
            obj1["code"] = res;
            obj1["from"] = "Abuja";
            obj1["to"] = "Yaba";
            obj1["quantity"] = share;
            Abuja.push(obj1);
          }
        } else if (
          abuja[res] < parseInt(yaba[res]) * 2 &&
          abuja[res] > parseInt(lekki[res]) * 2
        ) {
          if (abuja[res] === 1) {
            //nothing should happen
          } else {
            // divide yaba's stock and give only to Abuja
            const share = Math.floor(abuja[res] / 2);
            obj2["code"] = res;
            obj2["from"] = "Abuja";
            obj2["to"] = "Lekki";
            obj2["quantity"] = share;
            Abuja.push(obj2);
          }
        }
      } else {
        //skip
      }
    });
    Abuja.sort((a, b) => (a.to > b.to ? 1 : b.to > a.to ? -1 : 0));
    setAbujaTo(Abuja);
  }, [abuja, lekki, yaba]);

  useEffect(() => {
    if (yaba && abuja && lekki) {
      YabaOut();
      LekkiOut();
      AbujaOut();
    } else {
      setCurrentSelection("");
    }
  }, [LekkiOut, YabaOut, AbujaOut, yaba, abuja, lekki]);

  useEffect(() => {
    if (yabaTo) {
      setCurrentSelection(yabaTo);
    }
  }, [yabaTo]);

  const calculateSum = useCallback(
    (storeTo) => {
      if (yabaTo || abujaTo || lekkiTo) {
        let calc = 0;

        storeTo.forEach((res) => {
          calc += res.quantity;
        });
        setSum(calc);
      }
    },
    [yabaTo, lekkiTo, abujaTo]
  );

  const currentDate = () => {
    let newDate = new Date();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let date = newDate.getDate();

    return `${date}-${month}-${year}`;
  };

  useEffect(() => {
    if (selector === 1) {
      setCurrentSelection(yabaTo);
      calculateSum(yabaTo);
    } else if (selector === 2) {
      setCurrentSelection(abujaTo);
      calculateSum(abujaTo);
    } else if (selector === 3) {
      setCurrentSelection(lekkiTo);
      calculateSum(lekkiTo);
    }
  }, [selector, abujaTo, lekkiTo, yabaTo, calculateSum]);

  const getPageMargins = () => {
    return `@page { margin: 20px 20px 40px 20px} !important; }`;
  };

  const downloadCurrent = () => {
    const Text = () => {
      if (selector === 1) {
        return "Transfer_from_Yaba";
      } else if (selector === 2) {
        return "Transfer_from_Abuja";
      } else if (selector === 3) {
        return "Transfer_from_Lekki";
      }
    };

    const ttb = ReactDomServer.renderToStaticMarkup(
      <div
        style={{
          width: "700px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DisplayTable
          currentSelection={currentSelection}
          currentDate={currentDate}
          sum={sum}
        />
      </div>
    );

    doc.html(ttb, {
      width: 1000,
      html2canvas: { scale: 0.55 },
      margin: [20, 20, 40, 20],
      callback: () => {
        doc.save(`${Text()}-${currentDate()}.pdf`);
      },
    });
  };

  return (
    <div className="dist_main_container">
      <div className="dist_top_container">
        <div className="dist_each_cell">
          <div className="dist_text"> Yaba Store </div>
          <FormX setState={setYaba} />
        </div>
        <div className="dist_each_cell">
          <div className="dist_text"> Abuja Store </div>
          <FormX setState={setAbuja} />
        </div>
        <div className="dist_each_cell">
          <div className="dist_text"> Lekki Store </div>
          <FormX setState={setLekki} />
        </div>
      </div>
      <div className="dist_middle_container">
        <div className="dist_one">
          <div
            className={selector === 1 ? "dis_select" : "dis_tab"}
            onClick={() => setSelector(1)}
          >
            Transfer from Yaba
          </div>
          <div
            className={selector === 2 ? "dis_select" : "dis_tab"}
            onClick={() => setSelector(2)}
          >
            Transfer from Abuja
          </div>
          <div
            className={selector === 3 ? "dis_select" : "dis_tab"}
            onClick={() => setSelector(3)}
          >
            Transfer from Lekki
          </div>
        </div>
        <div className="dist_two">
          <button className="dist_btn" onClick={downloadCurrent}>
            Generate Pdf
          </button>
          <ReactToPrint
            trigger={() => <button className="dist_btn2"> Print </button>}
            content={() => TRef.current}
          />
          {/* <button className="dist_btn2"> Print </button> */}
        </div>
      </div>
      <div ref={TRef}>
        <style>{getPageMargins()}</style>
        <DisplayTable
          currentSelection={currentSelection}
          currentDate={currentDate}
          sum={sum}
        />
      </div>
    </div>
  );
};

const DisplayTable = ({ currentSelection, currentDate, sum }) => {
  return (
    <div className="dist_result_container">
      <table>
        <thead>
          <tr>
            <th> Date </th>
            <th> Store Sending </th>
            <th> Store Recieving </th>
            <th> Item Code </th>
            <th> Quantity </th>
          </tr>
        </thead>
        <tbody>
          {currentSelection.length > 0 &&
            currentSelection.map((res, i) => {
              return (
                <tr key={i}>
                  <td> {currentDate()} </td>
                  <td> {res.from} </td>
                  <td> {res.to} </td>
                  <td> {res.code} </td>
                  <td> {res.quantity} </td>
                </tr>
              );
            })}
          <tr>
            <td> </td>
            <td> </td>
            <td> </td>
            <td className="distro_td_last_child"> Total </td>
            <td className="distro_td_last_child"> {sum} </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MainPage;
