import React, { useEffect } from "react";
import Axios from "axios";
import "./DeleteUser.css";
const DeleteUser = (props) => {
  Axios.defaults.withCredentials = true;

  useEffect(() => {}, [props.id]);
  const cancel = () => {
    props.cancel();
  };

  const submit = async (e) => {
    e.preventDefault();

    await Axios.delete(`${process.env.REACT_APP_CLIENT_SERVER}/deleteusers`, {
      data: {
        id: props.id,
      },
    })
      .then((res) => {
        props.refresh();
        props.cancel();
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="se_divcc">
          <div className="se_div1 se_divflex sd_three">
            <div className="sd_two">
              <p>Are you sure you want to delete this entry? </p>
            </div>
            <div className="se_brrr">
              <button
                className="btn btn-primary se_btnyy"
                type="button"
                onClick={cancel}
              >
                X
              </button>
            </div>
          </div>
          <div className="se_btt2 sd_four">
            <button
              className="btn btn-primary sd_five se_btnyy"
              type="button"
              onClick={submit}
            >
              Yes 
            </button>
            <button
              className="btn btn-primary se_btnyy"
              type="button"
              onClick={cancel}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
