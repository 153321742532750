import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../Context";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Button, Modal } from "antd";
import { useClickAway } from "simple-react-clickaway";
import { FcSalesPerformance } from "react-icons/fc";
import { MdPolicy, MdPointOfSale } from "react-icons/md";
import { BsCalendarWeekFill, BsCashCoin } from "react-icons/bs";
import { HiChevronDown } from "react-icons/hi";
import { DiStreamline } from "react-icons/di";
import { VscChromeClose, VscSignIn } from "react-icons/vsc";
import { HiDotsHorizontal } from "react-icons/hi";
import { TiGift } from "react-icons/ti";
import { GiDiamondRing, GiHumanTarget, GiTrackedRobot } from "react-icons/gi";
import {
  FaUserCircle,
  FaSitemap,
  FaTimes,
  FaCheckCircle,
  FaJira,
} from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { findFlagUrlByNationality } from "country-flags-svg";
import "./Nav1.css";
import { CgProfile } from "react-icons/cg";
import { RiLockPasswordLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { ModalContainer } from "../../DealsSearch/DealsSearch.style";
import DealsSearch from "../../DealsSearch/DealsSearch";
import InvoicesSearch from "../InvoicesSearch";

const Nav1 = (props) => {
  const NContext = useContext(AppContext);
  const history = useHistory();

  const [sales, setsales] = useState("");
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [stock, setstock] = useState("");
  const [accessories, setaccessories] = useState(" ");
  const [petty, setpetty] = useState(" ");
  const settingRef = useRef();
  const profileRef = useRef();
  const handleSettingClickAway = () => {
    setOpenSettingModal(false);
  };
  const handleProfileClickAway = () => {
    setOpenProfileModal(false);
  };
  const { disable, enable } = useClickAway(settingRef, handleSettingClickAway);
  const { disable1, enable1 } = useClickAway(
    profileRef,
    handleProfileClickAway
  );

  const flagUrl = findFlagUrlByNationality("Nigerian");
  const flagUrl2 = findFlagUrlByNationality("Ghanaian");

  useEffect(() => {
    clickHandler();
  }, []);

  const clickHandler = () => {
    let id = props.id;
    switch (id) {
      case "sales":
        setsales("active ux_jjhe8839 ");
        setstock("us_jue");
        setaccessories("us_jue");
        setpetty("us_jue");
        break;
      case "stock":
        setsales("us_jue");
        setstock("active ux_jjhe8839");
        setaccessories("us_jue");
        setpetty("us_jue");
        break;
      case "accessories":
        setsales("us_jue");
        setstock("us_jue");
        setaccessories("active ux_jjhe8839");
        setpetty("us_jue");
        break;
      case "petty":
        setsales("us_jue");
        setstock("us_jue");
        setaccessories("us_jue");
        setpetty("active ux_jjhe8839");
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    let id = e.target.id;
    if (id === "Log Out") {
      history.push(`${process.env.REACT_APP_CLIENT_SERVER}/logout`);
    } else {
      history.push("/");
    }
  };

  const menuItems = [
    {
      name: "Sales Report",
      link: "/salesreport",
      icon: <MdPointOfSale className="menu_icon2" />,
    },
    {
      name: "Stock Report",
      link: "/stockreport",
      icon: <FaSitemap className="menu_icon2" />,
    },
    {
      name: "Accessories Report",
      link: "/accessoriesreport",
      icon: <TiGift className="menu_icon2" />,
    },
    {
      name: "PettyCash Report",
      link: "/pettyreport",
      icon: <BsCashCoin className="menu_icon2" />,
    },
    {
      name: "3dots",
      link: "#",
      icon: <HiDotsHorizontal className="menu_icon2" />,
    },
  ];

  return (
    <div className="main-container ">
      <div className="new-logo">
        <div className="left-logo-section">
          <GiDiamondRing
            style={{
              position: "absolute",
              top: "10px",
              left: "20px",
              // opacity: "0.4",
              zIndex: "100",
              fontSize: "50px",
            }}
          />
          {/* <img
            src="/assets/img/1480 (2).png"
            alt="App logo"
            className="logo-logo"
          /> */}
          <div
            className="logo-text"
            style={{
              marginLeft: "20px",
              // position: "absolute",
              zIndex: "200",
              backgroundColor: "#f3f4f6",
              padding: "-15px",
            }}
          >
            {" "}
            Delphi Applications
          </div>
        </div>
        <div className="right-logo-section">
          {!!props?.mored?.fname && (
            <div
              className="profile-name"
              onClick={() => setOpenProfileModal(!openProfileModal)}
              ref={profileRef}
            >
              {!!NContext?.storeiv &&
                `${props?.mored?.fname} ${props?.mored?.lname} - ${
                  NContext?.storeiv[0]?.toUpperCase() +
                  NContext?.storeiv?.substring(1)
                }`}
              <HiChevronDown />
              {!!openProfileModal && (
                <div className="profile-dropdown">
                  <ProfileDropDown
                    profile={props?.mored}
                    setOpenProfileModal={setOpenProfileModal}
                  />
                </div>
              )}
            </div>
          )}
          {!!props?.mored?.fname && (
            <div
              className="top-settings"
              onClick={() => setOpenSettingModal(!openSettingModal)}
              ref={settingRef}
            >
              <FiSettings />
              {!!openSettingModal && (
                <div className="settings-modal">
                  <SettingItem setOpenSettingModal={setOpenSettingModal} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="new-menu">
        {!!props.userd.username &&
          menuItems.map((menuObj, i) => {
            return <MenuItem menuObj={menuObj} key={`menuitems-${i}`} />;
          })}
      </div>
    </div>
  );
};

const MenuItem = ({ menuObj }) => {
  const modalRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInvoiceOpen, setIsModalInvoiceOpen] = useState(false);
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const history = useHistory();
  const handleClickAway = () => {
    setOpenModalMenu(false);
  };
  const { disable, enable } = useClickAway(modalRef, handleClickAway);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalInvoice = () => {
    setIsModalInvoiceOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalInvoiceOpen(false);
  };

  const dotItems = [
    {
      name: "Track it App",
      link: "/trackit",
      icon: <GiTrackedRobot className="menu_icon2" />,
    },
    {
      name: "Performance Report",
      link: "/performance",
      icon: <FcSalesPerformance className="menu_icon2" />,
    },
    {
      name: "Policies and Documents",
      link: "/policies",
      icon: <MdPolicy className="menu_icon2" />,
    },
    {
      name: "Distro App",
      link: "/distro",
      icon: <DiStreamline className="menu_icon2" />,
    },
    {
      name: "JIRA App",
      link: "https://delphimetals.atlassian.net/",
      icon: <FaJira className="menu_icon2" style={{ color: "#2e89fd" }} />,
    },
    {
      name: "Delphi HR App",
      link: "http://hr.delphimetals.com/",
      icon: <GiHumanTarget className="menu_icon2" />,
    },
    {
      name: "Weekly Report",
      link: "/weeklyreport",
      icon: (
        <BsCalendarWeekFill
          className="menu_icon2"
          style={{ color: "purple" }}
        />
      ),
    },
    {
      name: "Deals Checker",
      link: "/deals",
      icon: (
        <BsCalendarWeekFill className="menu_icon2" style={{ color: "green" }} />
      ),
    },
    {
      name: "Invoices Before 2023",
      link: "/invoices",
      icon: <DiStreamline className="menu_icon2" style={{ color: "red" }} />,
    },
    {
      name: "Log Out",
      link: `${process.env.REACT_APP_CLIENT_SERVER}/logout`,
      icon: <VscSignIn className="menu_icon2" />,
    },
  ];
  if (menuObj?.name === "3dots") {
    return (
      <div style={{ position: "relative" }}>
        <div
          ref={modalRef}
          className="dots-menu"
          onClick={() => {
            setOpenModalMenu(!openModalMenu);
          }}
        >
          {menuObj?.icon}
          {!!openModalMenu && (
            <div className="popout-menu">
              {dotItems.map((items, i) => {
                return (
                  <div
                    className="dot-item"
                    key={`dotItems-${i}`}
                    onClick={() => {
                      if (items?.name === "Log Out") {
                        window.open(items?.link, "_self");
                      } else if (items?.name === "JIRA App") {
                        window.open(items?.link, "_blank");
                      } else if (items?.name === "Delphi HR App") {
                        window.open(items?.link, "_blank");
                      } else if (items?.name === "Deals Checker") {
                        setIsModalOpen(true);
                      } else if (items?.name === "Invoices Before 2023") {
                        setIsModalInvoiceOpen(true);
                      } else {
                        history.push(items?.link);
                      }
                    }}
                  >
                    {items?.icon}
                    {items?.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <ModalContainer>
          <Modal
            style={{ top: 180 }}
            width={900}
            title="Deals-Check Viewer"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
          >
            <DealsSearch onCancel={handleCancel} />
          </Modal>
          <Modal
            style={{ top: 180 }}
            width={1100}
            title="Invoices Viewer"
            open={isModalInvoiceOpen}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
          >
            <InvoicesSearch onCancel={handleCancel} />
          </Modal>
        </ModalContainer>
      </div>
    );
  }

  return (
    <div className="menu-item" onClick={() => history.push(menuObj?.link)}>
      {menuObj?.icon}
      {menuObj?.name}
    </div>
  );
};

const SettingItem = ({ setOpenSettingModal }) => {
  const history = useHistory();

  const profileItems = [
    {
      name: "Profile",
      link: "/profile",
      icon: <CgProfile />,
    },
    {
      name: "Change Password",
      link: "/changepassword",
      icon: <RiLockPasswordLine />,
    },
    {
      name: "Add User",
      link: "/createuser",
      icon: <AiOutlineUserAdd />,
    },
    {
      name: "Edit User",
      link: "/users",
      icon: <BiEdit />,
    },
    {
      name: "Log Out",
      link: `${process.env.REACT_APP_CLIENT_SERVER}/logout`,
      icon: <VscSignIn className="menu_icon2" />,
    },
  ];

  return (
    <div className="setting-container">
      <div className="setting-section1">
        <div className="setting-text"> Settings</div>
        <div className="setting-times">
          <VscChromeClose />
        </div>
      </div>
      <div className="setting-section2">
        {profileItems.map((item, i) => {
          return (
            <div
              className="setting-item"
              key={`profile-items-${i}`}
              onClick={() => {
                if (item?.name === "Log Out") {
                  window.open(item?.link, "_self");
                } else {
                  history.push(item?.link);
                }
              }}
            >
              <div className="settings-icon2"> {item?.icon} </div>
              {item?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProfileDropDown = ({ profile }) => {
  const NContext = useContext(AppContext);
  const history = useHistory();
  return (
    <div className="profile-wrapper">
      <div className="profile-top">
        <div className="profile-close">
          <VscChromeClose />
        </div>
        <div className="profile-avatar">
          <img
            src="/assets/img/profile.png"
            className="profile-img"
            alt="avatar"
          />
        </div>
        <div className="profile-name2">
          {`${profile?.fname} ${profile?.lname}`}
        </div>
        <div className="profile-snum"> Staff ID: {profile?.snum} </div>
        <div className="profile-email"> {profile?.email} </div>
        <div
          className="profile-signout"
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_CLIENT_SERVER}/logout`,
              "_self"
            )
          }
        >
          Log Out
        </div>
      </div>
      <div className="profile-bottom">
        <div className="profile-switch"> SWITCH DEPARTMENTS </div>
        <div
          className="profile-store"
          onClick={() => NContext?.setstoreiv("yaba")}
        >
          Delphi Metals - Yaba
          {NContext?.storeiv === "yaba" && (
            <div className="profile-check">
              <FaCheckCircle />
            </div>
          )}
        </div>
        <div
          className="profile-store"
          onClick={() => NContext?.setstoreiv("abuja")}
        >
          Delphi Metals - Abuja
          {NContext?.storeiv === "abuja" && (
            <div className="profile-check">
              <FaCheckCircle />
            </div>
          )}
        </div>
        <div
          className="profile-store"
          onClick={() => NContext?.setstoreiv("lekki")}
        >
          Delphi Metals - Lekki
          {NContext?.storeiv === "lekki" && (
            <div className="profile-check">
              <FaCheckCircle />
            </div>
          )}
        </div>
        <div
          className="profile-store"
          onClick={() => NContext?.setstoreiv("accra")}
        >
          Delphi Metals - Accra
          {NContext?.storeiv === "accra" && (
            <div className="profile-check">
              <FaCheckCircle />
            </div>
          )}
        </div>
        <div
          className="profile-store"
          onClick={() => NContext?.setstoreiv("backoffice")}
        >
          Delphi Metals - BackOffice
          {NContext?.storeiv === "backoffice" && (
            <div className="profile-check">
              <FaCheckCircle />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav1;
