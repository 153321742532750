import React, { useState, useEffect } from "react";

const DateModule = ({ setWeekNo, setWeekDateString }) => {
  var curr = new Date(); // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  var firstday = new Date(curr.setDate(first)).toDateString();
  var lastday = new Date(curr.setDate(last)).toDateString();

  useEffect(() => {
    function getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
    }

    setWeekNo(getWeekNumber(new Date()));
    setWeekDateString(`${firstday} - ${lastday}`);
  }, [firstday, lastday, setWeekDateString, setWeekNo]);

  return (
    <div>
      <div className="main_container">
        {firstday} - {lastday}
        {/* <span className="wr_date_options">
          <select>
            <option> AAb </option>
            <option> AAb </option>
            <option> AAb </option>
            <option> AAb </option>
          </select>
        </span> */}
      </div>
    </div>
  );
};

export default DateModule;
