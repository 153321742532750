import React, { useState, useEffect, useContext } from "react";
import Datepicker from "react-datepicker";

import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import { AppContext } from "../../Context";
import "react-datepicker/dist/react-datepicker.css";
import "./Salesviewer.css";
import Axios from "axios";
import Salesedit from "./Salesedit";
import Salesdelete from "./Salesdelete";

const Salesviewer = (props) => {
  const SVContext = useContext(AppContext);
  Axios.defaults.withCredentials = true;
  const [startDate, setStartDate] = useState(new Date());
  const [data, setdata] = useState([{}]);
  const [editss, seteditss] = useState(false);
  const [editon, setediton] = useState(false);
  const [deleteon, setdeleteon] = useState(false);
  const [idd, setidd] = useState("");
  const [reload, setreload] = useState(true);

  const ddtt = new Date();

  // useEffect(() => {
  //   let dd = new Date();
  //   let ddm = dd.getMonth() + 1 + "/" + dd.getDate() + "/" + dd.getFullYear();
  //   seteditss(true);
  //   fetch(ddm);
  // }, []);

  useEffect(() => {
    let dd = new Date();
    let ddm = dd.getMonth() + 1 + "/" + dd.getDate() + "/" + dd.getFullYear();
    seteditss(true);
    fetch(ddm);
  }, [props.reload, deleteon, reload, SVContext.storeiv]);

  const fetch = async (month) => {
    setdata([{}]);
    await Axios.post(`${process.env.REACT_APP_CLIENT_SERVER}/sales`, {
      v_month: month,
      store: SVContext.storeiv,
    }).then((res) => {
      setdata(res.data.data.reverse());
    });
  };

  const clickedit = (e) => {
    e.preventDefault();
    if (
      SVContext.storeiv === SVContext.rrt.store &&
      SVContext.rrt.role === "sales"
    ) {
      setidd(e.target.id);
      setediton(true);
    }
  };
  const cancelit = () => {
    setediton(false);
    setdeleteon(false);
  };

  const deleteit = (e) => {
    if (
      SVContext.storeiv === SVContext.rrt.store &&
      SVContext.rrt.role === "sales"
    ) {
      setidd(e.target.id);
      setdeleteon(true);
    }
  };

  const submitit = () => {
    setediton(false);
    setdeleteon(false);
  };

  if (SVContext) {
    return (
      <div className="container sv_scrol ">
        <div id="accordion">
          <div className="card_sv">
            <div
              className="card-header si_rwiuev2s ux_dhyru23s"
              id="headingOne"
            >
              <h5 className="mb-0">
                <button
                  className="sales-viewer-header"
                  data-toggle="collapse"
                  data-target="#salv2"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Sales report Veiwer
                </button>
              </h5>
            </div>

            <div
              id="salv2"
              className="collapse show "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body"></div>

              <div className="sales_viewwer_datepicker">
                <div className="sales_viewwer_datepicker1">
                  <Datepicker
                    className="sales_viewwer_dt"
                    value={startDate}
                    selected={startDate}
                    onChange={async (date) => {
                      let ff =
                        date.getMonth() +
                        1 +
                        "/" +
                        date.getDate() +
                        "/" +
                        date.getFullYear();
                      await setStartDate(date);

                      if (
                        ddtt.getMonth() !== date.getMonth() ||
                        ddtt.getDate() !== date.getDate()
                      ) {
                        seteditss(false);
                      } else {
                        seteditss(true);
                      }

                      fetch(ff);
                    }}
                  />
                </div>
              </div>
              {editon ? (
                <div className="sv_wioeud">
                  <Salesedit
                    id={idd}
                    cancel={cancelit}
                    refresh={fetch}
                    userd={props.userd}
                    data={SVContext.rrt}
                    store={SVContext.storeiv}
                  />
                </div>
              ) : null}
              <div>
                {deleteon ? (
                  <div className="sv_wioeud">
                    <Salesdelete
                      id={idd}
                      cancel={cancelit}
                      refresh={fetch}
                      store={SVContext.storeiv}
                    />
                  </div>
                ) : null}
                {data.length > 0 ? (
                  <table
                    style={{ fontSize: "11px" }}
                    className="table table-striped table-bordered sv_ttbb"
                  >
                    {editss && SVContext.storeiv === SVContext.rrt.store ? (
                      <>
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Invoice Number</th>
                            <th scope="col">Ring Box(es)</th>
                            <th scope="col">Carrier Bag(s)</th>
                            <th scope="col">Ring Snuggie(s)</th>
                            <th scope="col">Silver Wipe(s) </th>
                            <th scope="col">Jewelry Box(es)</th>
                            <th scope="col">Jewelry Wipe(s)</th>
                            <th scope="col">Led Box(es)</th>
                            <th scope="col">Staff on Duty</th>
                            <th scope="col">Entry By</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((res, i) => {
                            return (
                              <tr key={i}>
                                <th scope="row">{res.v_date}</th>
                                <td>{res.v_invoice}</td>
                                <td>{res.v_ringCases}</td>
                                <td>{res.v_carrierBags}</td>
                                <td>{res.v_ringSunggies}</td>
                                <td>{res.v_silverPolish}</td>
                                <td>{res.v_jewelryCases}</td>
                                <td>{res.v_jewelryWipes}</td>
                                <td>{res.v_ledCases}</td>
                                <td>{res.v_staffDuty}</td>
                                <td>{res.v_entryby}</td>
                                <td>
                                  <div className="d-flex">
                                    <BiEditAlt
                                      className="salev_icon_wrapper"
                                      onClick={(e) => {
                                        setidd(res._id);
                                        clickedit(e);
                                      }}
                                      id={res._id}
                                    />
                                    <MdDeleteForever
                                      className=" salev_icon_wrapper"
                                      onClick={deleteit}
                                      id={res._id}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    ) : (
                      <tbody>
                        {data.map((res, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row">{res.v_date}</th>
                              <td>{res.v_invoice}</td>
                              <td>{res.v_ringCases}</td>
                              <td>{res.v_carrierBags}</td>
                              <td>{res.v_ringSunggies}</td>
                              <td>{res.v_silverPolish}</td>
                              <td>{res.v_jewelryCases}</td>
                              <td>{res.v_jewelryWipes}</td>
                              <td>{res.v_ledCases}</td>
                              <td>{res.v_entryby}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                ) : (
                  <div className="sv_nda"> No data</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinner
          animation="border "
          className="spinnerx2"
          role="status"
          style={{ width: "100px", height: "100px" }}
        ></Spinner>
        <div> Loading</div>
      </div>
    );
  }
};

export default Salesviewer;
