import React from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import "./UI.css";

const Button = ({ onClick, type, text }) => {
  if (type === "plus") {
    return (
      <div className="weeklyr_btn">
        <AiOutlinePlusCircle className="weeklyr_plus" onClick={onClick} />
      </div>
    );
  }
  if (type === "submit") {
    return (
      <div className="weekly_btn_submit">
        <button onClick={onClick}>{text}</button>
      </div>
    );
  }
  return (
    <div className="weeklyr_btn">
      <AiOutlineMinusCircle className="weeklyr_minus" onClick={onClick} />
    </div>
  );
};

export default Button;
