import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  ExpandDealBtn,
  InputSearch,
  ResetTable,
  SearchWrapper,
} from "../../DealsSearch/DealsSearch.style";
import { convertDate, numberWithCommas } from "../../DealsSearch/helpers";
import useGetInvoices from "../../Hooks/useGetInvoices";
import { Drawer } from "antd";
import Loading from "../Loading";
import { MainContainer, TableContainer } from "./InvoicesSearch.style";
import InvoiceDrawer from "./InvoiceDrawer";
import useGetInvoice from "../../Hooks/useGetInvoiceByNo";

const InvoicesSearch = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(30);
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");

  const { data } = useGetInvoices(limit, page);

  const { data: data2 } = useGetInvoice(search);

  useEffect(() => {
    if (data && !data2.data[0]?.invoiceNumber && search === "") {
      setInvoiceData(data);
      setTotalCount(data.total);
    } else if (data2?.data[0]?.invoiceNumber && search !== "") {
      setInvoiceData({
        data: data2?.data,
      });
      setTotalCount(1);
    }
  }, [data, data2, search]);

  useEffect(() => {
    setLoading(true);
  }, [page]);
  useEffect(() => {
    setLoading(false);
  }, [data, data2]);

  const showDrawer = (row) => {
    setOpen(true);
    setDrawerData({
      ...row,
      total: numberWithCommas(row.total),
      balance: numberWithCommas(row.balance),
      subTotal: numberWithCommas(row.subTotal),
      invoiceDate: convertDate(row.invoiceDate),
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const columnData = [
    {
      flex: 0.5,
      field: "invoiceNumber",
      headerName: "Invoice No",
    },
    {
      flex: 0.6,
      field: "invoiceDate",
      headerName: "Invoice Date",
      renderCell({ row }) {
        return <div>{convertDate(row.invoiceDate)}</div>;
      },
    },

    {
      flex: 1.5,
      field: "customerName",
      headerName: "Customer Name",
    },
    {
      flex: 0.5,
      field: "currencyCode",
      headerName: "Currency",
    },
    {
      flex: 0.7,
      field: "total",
      headerName: "Total",
      renderCell({ row }) {
        return <div>{numberWithCommas(row.total)}</div>;
      },
    },
    {
      flex: 1.5,
      field: "itemName",
      headerName: "Item Name",
    },
    {
      flex: 1,
      field: "cfStatus",
      headerName: "Invoice Status",
    },
    {
      flex: 0.8,
      headerName: "Expand",
      renderCell({ row }) {
        return (
          <>
            <ExpandDealBtn onClick={() => showDrawer(row)}>
              {" "}
              View{" "}
            </ExpandDealBtn>
          </>
        );
      },
    },
  ];
  const onSearch = (value) => {
    setSearch(value.toString());
  };
  if (invoiceData?.data && totalCount) {
    return (
      <MainContainer>
        <SearchWrapper>
          <InputSearch
            placeholder="Search for specific Invoice"
            onSearch={onSearch}
            style={{ width: 400 }}
            value={tempSearch}
            onChange={(e) => setTempSearch(e.target.value)}
          />
          <ResetTable
            onClick={() => {
              setTempSearch("");
              setSearch("");
            }}
          >
            Reset Table
          </ResetTable>
        </SearchWrapper>
        <TableContainer>
          <DataGrid
            style={{
              fontSize: "12px",
            }}
            rows={invoiceData.data}
            rowCount={totalCount}
            loading={loading}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={limit}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columnData}
            //   initialState={initialState}
          />
        </TableContainer>
        <Drawer
          title="Invoice Details"
          placement="right"
          onClose={onClose}
          open={open}
          width={400}
          zIndex={399999}
        >
          <InvoiceDrawer data={drawerData} />
        </Drawer>
      </MainContainer>
    );
  }
  return (
    <div>
      <Loading size="large" />
    </div>
  );
};

export default InvoicesSearch;
