import React from "react";
import { MenuItem, NavContainer } from "./styles";

const NavBar = ({ data }) => {
  return (
    <NavContainer>
      <MenuItem onClick={() => data.changeMenu(0)} highlight={data.num === 0}>
        Track Order
      </MenuItem>
      {(data.data && data.mored.role === "accounts") ||
      data.mored.role === "admin" ? (
        <>
          <MenuItem
            onClick={() => data.changeMenu(1)}
            highlight={data.num === 1}
          >
            New Order
          </MenuItem>
          <MenuItem
            onClick={() => data.changeMenu(2)}
            highlight={data.num === 2}
          >
            Update Pending Orders
          </MenuItem>
          <MenuItem
            onClick={() => data.changeMenu(3)}
            highlight={data.num === 3}
          >
            View Completed Orders
          </MenuItem>
        </>
      ) : null}
    </NavContainer>
  );
};

export default NavBar;
