import React, { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { IoAddCircle } from "react-icons/io5";
import Axios from "axios";
import "./Users.css";
import DeleteUser from "./DeleteUser";
import EditUsers from "./EditUsers";
import Spinnerxx from "../Utils/Spinnerxx";

const Users = (props) => {
  const [data, setdata] = useState({});
  const [id, setid] = useState("");
  const [deleteon, setdeleteon] = useState("hidden");
  const [editon, setediton] = useState("hidden");
  const [rf, setrf] = useState(true);

  useEffect(() => {
    fetchMe();
  }, [rf]);

  const fetchMe = async () => {
    await Axios.get(`${process.env.REACT_APP_CLIENT_SERVER}/users`)
      .then((res) => {
        setdata(res.data);
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  };

  const cancel = () => {
    setid();
    setdeleteon("hidden");
    setediton("hidden");
  };

  const refreshx = () => {
    setrf(!rf);
  };

  if (props.data.role === "admin" && data.data) {
    return (
      <>
        {deleteon === "show" ? (
          <span className={`${deleteon}`}>
            <DeleteUser
              data={data}
              id={id}
              cancel={cancel}
              refresh={refreshx}
            />
          </span>
        ) : null}
        {editon === "show" ? (
          <span className={`${editon}`}>
            <EditUsers data={data} id={id} cancel={cancel} refresh={refreshx} />
          </span>
        ) : null}

        <div>
          <div>
            <div className="container us_cc">
              <div className="row">
                <div className="col-md-4">
                  <h2 style={{ width: "343px" }}>List of Users</h2>
                </div>
                <div className="col-md-4 d-flex justify-content-end align-self-start"></div>
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary d-flex align-items-center align-self-center"
                    type="button"
                    style={{ height: "38px", backgroundColor: "rgb(21,221,4)" }}
                  >
                    <a href="/createuser" alt="add user">
                      Add User
                    </a>
                     
                    <i className="fa fa-plus-circle">
                      <IoAddCircle />
                    </i>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table
                    id="example"
                    className="table table-striped table-bordered"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Staff Number</th>
                        <th>Name</th>
                        <th>Store</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.map((res) => {
                        return (
                          <tr key={res._id}>
                            <td>{res.snum} </td>
                            <td>{res.fname + " " + res.lname}</td>
                            <td>{res.store}</td>
                            <td>{res.role}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger"
                                id={res._id}
                                onClick={() => {
                                  setid(res._id);
                                  setdeleteon("show");
                                  document.documentElement.scrollTop = 0;
                                }}
                              >
                                <i className="far fa-trash-alt d-xl-flex justify-content-xl-center align-items-xl-center">
                                  <RiDeleteBin6Line />
                                </i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning"
                                id={res._id}
                                onClick={() => {
                                  setid();
                                  setid(res._id);
                                  setediton("show");
                                  document.documentElement.scrollTop = 0;
                                }}
                              >
                                <i className="fas fa-pencil-alt d-xl-flex justify-content-xl-center align-items-xl-center">
                                  <BiEditAlt />
                                </i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin">
          You are not Authorized to view this page
        </div>
      </div>
    );
  }
};

export default Users;
