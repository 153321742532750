import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";

export const AppContext = createContext(null);

const Index = ({ children }) => {
  const [rrt, setrrt] = useState({});
  const [storeiv, setstoreiv] = useState("");
  const [logged, setLogged] = useState(false);

  const value = {
    rrt,
    setrrt,
    storeiv,
    setstoreiv,
    logged,
    setLogged,
  };

  return <AppContext.Provider value={value}> {children} </AppContext.Provider>;
};

export default Index;
