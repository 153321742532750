import React from "react";
import xlsx from "xlsx";
import Input from "../Input";
import Label from "../Label";

const FormX = ({ setState }) => {
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        TrimDown(json);
      };
      if (e.target.files[0]) {
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  };

  const TrimDown = (json) => {
    let obj = {};
    json.forEach((res) => {
      obj[[res.SKU]] = res["Stock On Hand"];
    });

    setState(obj);
  };

  return (
    <form>
      <Label />
      <Input readUploadFile={readUploadFile} />
    </form>
  );
};

export default FormX;
