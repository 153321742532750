import React from "react";
import "./AdminPanel.css";
import NavBar from "./NavBar";
import { AdminPanelContainer } from "./styles";
const AdminPanel = (props) => {
  return (
    <AdminPanelContainer>
      <NavBar data={props} />
    </AdminPanelContainer>
  );
};

export default AdminPanel;
