import styled from "styled-components";
import { Button } from "antd";
import { Input } from "antd";

const { Search } = Input;

export const ModalContainer = styled.div``;

export const DealSearchContainer = styled.div``;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  height: 650px;
`;

export const ExpandDealBtn = styled.div`
  background: #66a6ff;
  width: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
`;

export const DealsDrawerWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 10px 10px 40px;
  overflow-x: scroll;
  /* margin-top: 10px; */
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;

export const ItemWrapper = styled.div`
  margin-top: 15px;
  font-size: 13px;
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
`;

export const Key = styled.div`
  font-weight: bold;
`;
export const Value = styled.div`
  margin-left: 10px;
`;

export const Footer = styled.div``;

export const ViewBtn = styled(Button)`
  margin-top: 15px;
`;

export const CloseBtn = styled.div``;

export const InputSearch = styled(Search)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ResetTable = styled.div`
  position: relative;
  top: 8px;
  margin-left: 5px;
  font-size: 12px;
  color: blue;
  cursor: pointer;
`;
