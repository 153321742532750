import React, { useState, useEffect, useCallback } from "react";
import DateModule from "../Components/DateModule";
import { BsPatchCheckFill } from "react-icons/bs";
import Axios from "axios";
import QA from "../Components/QA";
import Button from "../Components/UI/Button";

import "./MainPage.css";

const MainPage = ({ data, userd }) => {
  const [questionState, setQuestionState] = useState({});
  const [weekNo, setWeekNo] = useState();
  const [weekDateString, setWeekDateString] = useState();
  const [datax, setDatax] = useState();
  const [status, setStatus] = useState("loading");
  const [cta, setCta] = useState("submit");

  const getInitialData = useCallback(async () => {
    if (data) {
      await Axios.get(
        `${process.env.REACT_APP_SERVER}/weeklyreport/${data?.username}/${data?.store}`
      ).then((res) => {
        if (res.data) {
          setStatus(res?.data?.response);
          setDatax(res?.data?.data);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (datax) {
      setQuestionState(JSON.parse(datax[0]?.qa));
    }
  }, [datax]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const submitForm = async (e) => {
    e.preventDefault();
    let obj = {
      qa1: JSON.stringify(questionState),
      weekNo1: weekNo[1],
      weekDateString1: weekDateString,
      staffEmail1: data?.email,
      store1: data?.store,
      fname1: data?.fname,
      lname1: data?.lname,
      year1: weekNo[0],
      date1: new Date(),
    };

    await Axios.post(`${process.env.REACT_APP_SERVER}/weeklyreport`, obj).then(
      (res) => {
        if (res) {
          if (res?.data?.response === "Data saved") {
            setStatus("saved");
          }
        } else {
          console.log("error");
        }
      }
    );
  };

  const updateForm = async (e) => {
    e.preventDefault();
    let obj = {
      qa1: JSON.stringify(questionState),
      weekNo1: weekNo[1],
      weekDateString1: weekDateString,
      staffEmail1: data?.email,
      store1: data?.store,
      fname1: data?.fname,
      lname1: data?.lname,
      year1: weekNo[0],
      date1: new Date(),
    };

    await Axios.put(`${process.env.REACT_APP_SERVER}/weeklyreport`, obj).then(
      (res) => {
        if (res) {
          if (res?.data === "successful") {
            setStatus("saved");
          }
        } else {
          console.log("error");
        }
      }
    );
  };

  if (status === "loading") {
    return (
      <div className="weekly_loading">
        <img src="/imgs/832 (1).gif" alt="data loading" />
      </div>
    );
  }

  if (status === "saved") {
    return (
      <div className="weekly_submitted_div">
        <div>
          <BsPatchCheckFill className="weekly_saved" />
        </div>
        <p> {data?.fname}, your weekly report has been saved! </p>
      </div>
    );
  }

  if (status === true) {
    return (
      <div className="weekly_submitted_div">
        <p> You have submitted your weekly report for this week. </p>
        <p>
          Click
          <span
            className="weekly_span_edit"
            onClick={() => {
              setStatus(false);
              setCta("update");
            }}
          >
            here
          </span>
          if you want to edit it
        </p>
      </div>
    );
  }

  return (
    <div className="container">
      <div className=" date_container">
        <DateModule
          setWeekNo={setWeekNo}
          setWeekDateString={setWeekDateString}
        />
      </div>
      <div className="qa_qestions">
        <QA
          question="1. Achievement for the week under review?"
          multiple={true}
          setstate={setQuestionState}
          state={questionState}
          codename="QA1"
          required={true}
          type="text"
        />
        <QA
          question="2. How much sales did you make this week?"
          multiple={false}
          setstate={setQuestionState}
          state={questionState}
          codename="QA2"
          required={true}
          type="number"
        />
        <QA
          question="3. Challenges faced during the week under review?"
          multiple={true}
          setstate={setQuestionState}
          state={questionState}
          codename="QA3"
          required={true}
          type="text"
        />
        <QA
          question="4. What are your plans for next week to improve your sales figure?"
          multiple={true}
          setstate={setQuestionState}
          state={questionState}
          codename="QA4"
          required={true}
          type="text"
        />
        <QA
          question="5. Expectations for next week (if different from question 4)?"
          multiple={true}
          setstate={setQuestionState}
          state={questionState}
          codename="QA5"
          required={false}
          type="text"
        />
        <QA
          question="6. Suggestions for management?"
          multiple={true}
          setstate={setQuestionState}
          state={questionState}
          codename="QA6"
          required={true}
          type="text"
        />
        {cta === "submit" && (
          <Button type="submit" text="Submit" onClick={submitForm} />
        )}
        {cta === "update" && (
          <Button type="submit" text="Update" onClick={updateForm} />
        )}
      </div>
    </div>
  );
};

export default MainPage;
