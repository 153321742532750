import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { AppContext } from "../../Context";
import { Spinner } from "react-bootstrap";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { TiPin } from "react-icons/ti";
import Axios from "axios";

import "./Pettycash.css";
import Pettyedit from "./Pettyedit";
import Pcdelete from "./Pcdelete";
import Pettyviewer from "./Pettyviewer";
import { AiFillPropertySafety } from "react-icons/ai";
import Spinnerxx from "../Utils/Spinnerxx";

const Pettycash = (props) => {
  const PContext = useContext(AppContext);
  const [disburse, setdisburse] = useState("pc_nsell");
  const [reimburse, setreimburse] = useState("pc_sell");
  const [btntxt, setbtntxt] = useState("Disburse");
  const [btnid, setbtnid] = useState("disburse");
  const [amount, setamount] = useState(0);
  const [description, setdescription] = useState("");
  const [btnactive, setbtnactive] = useState("disabled");
  const [msg, setmsg] = useState("msg");
  const [spanv, setspanv] = useState("hidden");
  const [dc, setdc] = useState([{}]);
  const [total, settotal] = useState(0);
  const [editdata, seteditdata] = useState({});
  const [deldata, setdeldata] = useState({});
  let totalr = 0;
  // let tt;

  const getData = useCallback(async () => {
    // tt = PContext.storeiv;
    const dd = new Date();
    let dm = dd.getMonth() + 1;
    let year = new Date().getFullYear().toString();
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/pettycashmain/${dm}/${year}/${PContext.storeiv}`
    )
      .then((res) => {
        setdc(res.data.data.reverse());
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  }, [PContext.storeiv]);

  const numberWithCommas = (x) => {
    x = String(x);
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const gettotals = () => {
    dc.map((res) => {
      return (totalr =
        parseInt(totalr) + (parseInt(res.cr) - parseInt(res.dr)));
    });
    settotal(numberWithCommas(totalr));
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    gettotals();
  }, [dc, PContext.storeiv]);

  const pc_ss_clear = () => {
    seteditdata({});
    setdeldata({});
  };

  const focusx = (x) => {
    if (x === "disburse") {
      setdisburse("pc_nsell");
      setreimburse("pc_sell");
      setbtntxt("Disburse");
      setbtnid("disburse");
    } else if (x === "reimburse") {
      setdisburse("pc_sell");
      setreimburse("pc_nsell");
      setbtntxt("Reimburse");
      setbtnid("reimburse");
    }
  };

  const checkHandler = (e) => {
    if (e.target.checked) {
      setbtnactive(" ");
      document.getElementById(btnid).style.cursor = "pointer";
      document.getElementById(btnid).style.backgroundColor = "rgb(64, 41, 85)";
      document.getElementById(btnid).style.color = "white";
    } else {
      setbtnactive("disabled");
      document.getElementById(btnid).style.cursor = "default";
    }
  };

  const editMe = async (id) => {
    let ty = PContext.storeiv;
    const dd = new Date();
    let ddm = dd.getMonth() + 1;
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/pettycash/edit/${ddm}/${id}/${ty}`
    ).then((res) => {
      seteditdata(res.data.data);
    });
  };

  const deleteMe = async (id) => {
    let ty = PContext.storeiv;
    setdeldata({});
    const dd = new Date();
    let ddm = dd.getMonth() + 1;
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/pettycash/edit/${ddm}/${id}/${ty}`
    ).then((res) => {
      setdeldata(res.data.data);
    });
  };

  const submitHandler = async (e) => {
    let ty = PContext.storeiv;
    const dd = new Date();
    let ddm = dd.getMonth() + 1;

    let ddt = ddm + "/" + dd.getDate() + "/" + dd.getFullYear();

    e.preventDefault();
    let id = e.target.id;
    if (
      document.getElementById("exampleCheck1").checked === true &&
      amount > 0 &&
      description.length > 2
    ) {
      if (id === "disburse") {
        await Axios.post(
          `${process.env.REACT_APP_CLIENT_SERVER}/pettycashpost/disburse/${ddm}/${ty}`,
          {
            amount: amount,
            description: description,
            date: ddt,
            month: ddm,
            v_entryby: PContext.rrt.fname + " " + PContext.rrt.lname,
          }
        )
          .then((res) => {
            setmsg(res.data.msg);
            setspanv("pt_show");
            setTimeout(() => {
              setspanv("hidden");
            }, 3000);

            setamount(0);
            setdescription(" ");
            setbtnactive("disabled");
            document.getElementById("exampleCheck1").checked = false;
          })
          .catch((e) => {
            if (e) {
              console.log(e);
            }
          });
        getData();
      } else if (id === "reimburse") {
        await Axios.post(
          `${process.env.REACT_APP_CLIENT_SERVER}/pettycashpost/reimburse/${ddm}/${ty}`,
          {
            amount: amount,
            description: description,
            date: ddt,
            month: ddm,
            v_entryby: PContext.rrt.fname + " " + PContext.rrt.lname,
          }
        )
          .then((res) => {
            setmsg(res.data.msg);
            setspanv("pt_show");
            setTimeout(() => {
              setspanv("hidden");
            }, 2000);

            setamount(0);
            setdescription(" ");
            setbtnactive("disabled");
            document.getElementById("exampleCheck1").checked = false;
          })
          .catch((e) => {
            if (e) {
              console.log(e);
            }
          });
        getData();
      }
    } else {
    }
  };

  if (props.userd.username && PContext.storeiv) {
    return (
      <div>
        <div
          className={`container ${
            PContext.storeiv === PContext.rrt.store ? null : "disabled"
          } `}
        >
          <div className="row_pchead">
            <div className="col_pchead">
              <div>
                <p className="">Petty Cash Report</p>
              </div>
              <div>
                <p className="" style={{ width: "100%" }}>
                  Cash Available: 
                  <span className="pc1_cashavailable ">{total}</span>
                </p>
              </div>
            </div>
          </div>

          <div id="accordion">
            <div className="card">
              <div
                className="card-header si_rwiuevs ux_dhyru23s"
                id="headingOne"
              >
                <h5 className="mb-0">
                  <button
                    className="sales-report-form"
                    data-toggle="collapse"
                    data-target="#pc"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Petty Cash Form
                  </button>
                </h5>
              </div>

              <div
                id="pc"
                className="collapse  "
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                    <div className="col pc_col1">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 gth">
                          <div className="pc_diva">
                            <div className="row ppc_r1">
                              <div
                                className={`col pc_nactive pt_wioew   ${disburse}`}
                                onClick={() => {
                                  focusx("disburse");
                                }}
                              >
                                <p className="pc_cent">Disbursement</p>
                              </div>
                              <div
                                className={`col pc_nactive  pc_dwe ${reimburse}`}
                                onClick={() => {
                                  focusx("reimburse");
                                }}
                              >
                                <p className="pc_cent">Reimbursement</p>
                              </div>
                            </div>
                            <div className={`row pc_rww3 ${btnid} pc_dwe`}>
                              {/* <span className={`${spanv}`}> {msg} </span> */}
                              <div className="col pc_inp">
                                <div className="petty_amount_flex">
                                  <label id="tet" className="petty_flex_label ">
                                    Amount: 
                                  </label>

                                  <input
                                    type="number"
                                    className="sales_input_petty"
                                    maxLength="10"
                                    id="inp1"
                                    value={amount}
                                    onChange={(e) => {
                                      setamount(e.target.value);
                                    }}
                                    onFocus={(e) => {
                                      document.getElementById("inp1").select();
                                    }}
                                  />
                                </div>
                                <div className="petty_amount_flex">
                                  <label className="petty_flex_label">
                                    Description: 
                                  </label>

                                  <input
                                    type="text"
                                    className="sales_input_petty"
                                    value={description}
                                    onChange={(e) => {
                                      setdescription(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="row">
                                  <div className="col pc_ingo ">
                                    <span className="pc_ssp  ">
                                      <TiPin />
                                    </span>
                                    {"  "}
                                    <span className="pt_eow ">
                                      Remember to reimburse the closing balance
                                      from previous month as the opening balance
                                      of a new month
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col pc_ingo pc_ccc">
                                    <input
                                      type="checkbox"
                                      className="form-check-input "
                                      id="exampleCheck1"
                                      onClick={checkHandler}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleCheck1"
                                    >
                                      Data verified and confirmed accurate
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center pc_btjs">
                                    <button
                                      className={`  ${btnactive} petty_btn `}
                                      type="button"
                                      id={btnid}
                                      onClick={submitHandler}
                                      style={{ cursor: "default" }}
                                    >
                                      {btntxt.toUpperCase()}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col"></div>
                      </div>
                    </div>

                    <div className="col pc_bb">
                      {editdata.length > 0 ? (
                        <Pettyedit
                          data={editdata}
                          clear={pc_ss_clear}
                          ss={getData}
                          userd={props.userd}
                          datar={PContext.rrt}
                          store={PContext.storeiv}
                        />
                      ) : null}
                      {deldata.length > 0 ? (
                        <Pcdelete
                          data={deldata}
                          clear={pc_ss_clear}
                          ss={getData}
                          userd={props.userd}
                          datar={PContext.rrt}
                          store={PContext.storeiv}
                        />
                      ) : null}
                      <div className="pc_hhu pc_ttbs pt_wioews">
                        <div>
                          <table className="table table-striped table-bordered fixed_headers pt_wioews">
                            <thead className="thead-light ">
                              <tr>
                                <th className="dhdhd pc_tren " scope="col">
                                  Date
                                </th>
                                <th scope="col" className="pc_tren">
                                  CR
                                </th>
                                <th scope="col" className="pc_tren">
                                  DR
                                </th>
                                <th scope="col" className="pc_tren">
                                  Description
                                </th>
                                <th scope="col" className="pc_tren">
                                  Action
                                </th>
                                <th scope="col" className="pc_tren">
                                  Edited
                                </th>
                                <th scope="col" className="pc_tren">
                                  Entry By
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {dc &&
                                dc.map((res, i) => {
                                  return (
                                    <tr key={i}>
                                      <th scope="row">{res.date}</th>

                                      <td>{numberWithCommas(res.cr)}</td>
                                      <td>{numberWithCommas(res.dr)}</td>
                                      <td>{res.description}</td>
                                      <td className="d-flex">
                                        <BiEditAlt
                                          style={{
                                            fontSize: "23px",
                                            marginRight: "10px",
                                            marginBottom: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setdeldata({});
                                            editMe(res._id, res.date);
                                          }}
                                        />

                                        <MdDeleteForever
                                          style={{
                                            fontSize: "23px",
                                            marginRight: "10px",
                                            marginBottom: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            seteditdata({});
                                            deleteMe(res._id);
                                          }}
                                        />
                                      </td>
                                      <td>{res.edate}</td>
                                      <td>{res.v_entryby}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default Pettycash;
