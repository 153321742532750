import React, { useState, useEffect } from "react";
import Axios from "axios";

const useGetDaa = (x) => {
  const [rrt, setrrt] = useState({});
  const [storeiv, setstoreiv] = useState("");

  useEffect(() => {
    if (x) {
      getdaa();
    }
  }, [x]);

  const getdaa = () => {
    Axios.get(`${process.env.REACT_APP_CLIENT_SERVER}/moredetails`, {
      withCredentials: true,
      credentials: "include",
    }).then((res) => {
      setrrt(res.data);
      setstoreiv(res.data.store);
    });
  };

  return { rrt, storeiv };
};

export default useGetDaa;
