import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../Context";
import Axios from "axios";
import "./ViewStock.css";
import ButtonDropdown from "../Utils/ButtonDropdown";
import Spinnerxx from "../Utils/Spinnerxx";
import { BsBoxArrowInDownRight } from "react-icons/bs";
import YearDropdown from "../Utils/YearDropdown";

const ViewStock = (props) => {
  const VSContext = useContext(AppContext);
  Axios.defaults.withCredentials = true;
  const [data, setdata] = useState();
  const [monthx, setMonthx] = useState();
  const [ddname, setddname] = useState("Select Month");
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [reload, setreload] = useState(true);

  const ty = useCallback(async () => {
    // effect;
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/viewstock/${monthx}/${year}/${VSContext.storeiv}`
    )
      .then((res) => {
        setdata(res.data);
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  }, [VSContext.storeiv, year, monthx]);

  useEffect(() => {
    let dd = new Date();
    let month = dd.getMonth() + 1;
    if (month === 13) {
      month = 1;
    }
    setMonthx(month);
  }, []);

  useEffect(() => {
    ty();
  }, [ty, reload, VSContext.storeiv]);

  // const changeMonth = async (month) => {
  //   await Axios.get(
  //     `${process.env.REACT_APP_CLIENT_SERVER}/viewstock/${month}/${year}/${VSContext.storeiv}`
  //   )
  //     .then((res) => {
  //       setdata(res.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const jump = (e) => {
    e.preventDefault();
    let id = parseInt(e.target.id);
    setMonthx(id);
    if (parseInt(id) === 1) {
      setddname("January");
    } else if (id === 2) {
      setddname("February");
    } else if (id === 3) {
      setddname("March");
    } else if (id === 4) {
      setddname("April");
    } else if (id === 5) {
      setddname("May");
    } else if (id === 6) {
      setddname("June");
    } else if (id === 7) {
      setddname("July");
    } else if (id === 8) {
      setddname("August");
    } else if (id === 9) {
      setddname("September");
    } else if (id === 10) {
      setddname("October");
    } else if (id === 11) {
      setddname("November");
    } else if (id === 12) {
      setddname("December");
    }

    // changeMonth(id);
  };

  if (props.userd.username && VSContext.storeiv) {
    if (data) {
      return (
        <div className="sv_scrol">
          <div id="accordion">
            <div className="card">
              <div
                className="card-header si_rwiuevs ux_dhyru23s"
                id="headingOne"
              >
                <h5 className="mb-0">
                  <button
                    className="sales-report-form"
                    data-toggle="collapse"
                    data-target="#sr"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Stock Report Viewer
                  </button>
                </h5>
              </div>

              <div
                id="sr"
                className="collapse "
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="pv_flex">
                    <ButtonDropdown jump={jump} ddname={ddname} />
                    <YearDropdown setYearx={setYear} />
                  </div>
                  <table
                    style={{ fontSize: "11px" }}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Sterling Silver Engagement Rings</th>
                        <th scope="col">Gold Engagement Rings</th>
                        <th scope="col">Sterling Silver Bridal Sets</th>
                        <th scope="col">Titanium / Tungsten Bands</th>
                        <th scope="col">Necklaces</th>
                        <th scope="col">Sterling Silver Bands</th>
                        <th scope="col">Stainless Steel Bands</th>
                        <th scope="col">Titanium Engagement Rings</th>
                        <th scope="col">Gold Bands</th>
                        <th scope="col">Entry By</th>
                        <th scope="col">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.reverse().map((res) => {
                        return (
                          <tr key={res._id}>
                            <th scope="row">{res.v_date}</th>
                            <td>{res.v_ss_eng}</td>
                            <td>{res.v_g_eng}</td>
                            <td>{res.v_ss_bbset}</td>
                            <td>{res.v_t_band}</td>
                            <td>{res.v_necklace}</td>
                            <td>{res.v_ss_band}</td>
                            <td>{res.v_st_band}</td>
                            <td>{res.v_t_eng}</td>
                            <td>{res.v_g_bands}</td>
                            <td>{res.v_entryby}</td>
                            <td>
                              <a
                                className="ukbtnx"
                                data-toggle="collapse"
                                href={`#tt${res._id}`}
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                <BsBoxArrowInDownRight />
                              </a>

                              <div className="collapse" id={`tt${res._id}`}>
                                <div className="card card-body">
                                  {res.v_remarks}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading</div>;
    }
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default ViewStock;
