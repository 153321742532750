import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../Context";
import { BiReset } from "react-icons/bi";
import { FiSend } from "react-icons/fi";

import Axios from "axios";
import "./FormInput.css";
import Spinnerxx from "../Utils/Spinnerxx";
const FormInput = (props) => {
  const FIContext = useContext(AppContext);
  const [ringCases, setringCases] = useState(0);
  const [carrierBags, setcarrierBags] = useState(0);
  const [ledCases, setledCases] = useState(0);
  const [ringSunggies, setringSunggies] = useState(0);
  const [silverPolish, setsilverPolish] = useState(0);
  const [jewelryCases, setjewelryCases] = useState(0);
  const [jewelryWipes, setjewelryWipes] = useState(0);
  const [velvetCases, setvelvetCases] = useState(0);
  const [vvs, setvvs] = useState("hidden");
  const [errm, seterrm] = useState("");

  //////////////////////USE EFFECT /////////////////////
  useEffect(() => {}, []);

  ///////////////      CHANGE HANDLER FOR INPUT    ////////////////
  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (!isNaN(value)) {
      document.getElementById(id).style.color = "black";
      setvvs("hidden");
      if (value.includes(".")) {
        document.getElementById(id).style.color = "red";
      } else {
        // Action point
        if (id === "ringCases") {
          setringCases(value);
        } else if (id === "carrierBags") {
          setcarrierBags(value);
        } else if (id === "ledCases") {
          setledCases(value);
        } else if (id === "ringSunggies") {
          setringSunggies(value);
        } else if (id === "silverPolish") {
          setsilverPolish(value);
        } else if (id === "jewelryCases") {
          setjewelryCases(value);
        } else if (id === "jewelryWipes") {
          setjewelryWipes(value);
        } else if (id === "velvetCases") {
          setvelvetCases(value);
        }
      }
    } else {
      document.getElementById(id).style.color = "red";
      setvvs("visible");
      seterrm("Value must be a Number");
      if (id === "ringCases") {
        setringCases(parseInt(0));
      } else if (id === "carrierBags") {
        setcarrierBags(parseInt(0));
      } else if (id === "ledCases") {
        setledCases(parseInt(0));
      } else if (id === "ringSunggies") {
        setringSunggies(parseInt(0));
      } else if (id === "silverPolish") {
        setsilverPolish(parseInt(0));
      } else if (id === "jewelryCases") {
        setjewelryCases(parseInt(0));
      } else if (id === "jewelryWipes") {
        setjewelryWipes(parseInt(0));
      } else if (id === "velvetCases") {
        setvelvetCases(parseInt(0));
      }
    }
  };

  //////////////////////// RESET BUTTON//////////////////////////
  const reset = (e) => {
    if (e) {
      e.preventDefault();
    }

    setringCases(0);
    setcarrierBags(0);
    setledCases(0);
    setringSunggies(0);
    setsilverPolish(0);
    setjewelryCases(0);
    setjewelryWipes(0);
    setvelvetCases(0);
  };
  //////////////////////// SAVE BUTTON ///////////////////////////
  const save = (e) => {
    e.preventDefault();
    let dd = new Date();
    let ddm = dd.getMonth() + 1;
    let [month, date, year] = new Date().toLocaleDateString("en-US").split("/");
    let c_date = `${month}/${date}/${year}`;
    let values = {
      v_ringCases: parseInt(ringCases),
      v_carrierBags: parseInt(carrierBags),
      v_ledCases: parseInt(ledCases),
      v_ringSunggies: parseInt(ringSunggies),
      v_silverPolish: parseInt(silverPolish),
      v_jewelryCases: parseInt(jewelryCases),
      v_jewelryWipes: parseInt(jewelryWipes),
      v_velvetCases: parseInt(velvetCases),
      v_date: c_date,
      v_month: ddm,
      v_entryby: FIContext.rrt.fname + " " + FIContext.rrt.lname,
    };
    post(values);
  };

  const post = async (values) => {
    await Axios.post(`${process.env.REACT_APP_CLIENT_SERVER}/postit`, {
      v_date: values.v_date,
      v_ringCases: values.v_ringCases,
      v_carrierBags: values.v_carrierBags,
      v_ledCases: values.v_ledCases,
      v_ringSunggies: values.v_ringSunggies,
      v_silverPolish: values.v_silverPolish,
      v_jewelryCases: values.v_jewelryCases,
      v_jewelryWipes: values.v_jewelryWipes,
      v_velvetCases: values.v_velvetCases,
      v_month: values.v_month,
      v_entryby: values.v_entryby,
      store: FIContext.storeiv,
    })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById("ringCases").disabled = true;
          document.getElementById("silverPolish").disabled = true;
          document.getElementById("carrierBags").disabled = true;
          document.getElementById("jewelryCases").disabled = true;
          document.getElementById("ledCases").disabled = true;
          document.getElementById("jewelryWipes").disabled = true;
          document.getElementById("velvetCases").disabled = true;
          document.getElementById("ringSunggies").disabled = true;
          document.getElementById("btn_h").disabled = true;
          document.getElementById("guu3").style.color = "green";
          setvvs("visible");
          seterrm("Data saved!");
          setTimeout(() => {
            setvvs("hidden");
            document.getElementById("guu3").style.color = "red";
            reset();
            document.getElementById("ringCases").disabled = false;
            document.getElementById("silverPolish").disabled = false;
            document.getElementById("carrierBags").disabled = false;
            document.getElementById("jewelryCases").disabled = false;
            document.getElementById("ledCases").disabled = false;
            document.getElementById("jewelryWipes").disabled = false;
            document.getElementById("velvetCases").disabled = false;
            document.getElementById("ringSunggies").disabled = false;
            document.getElementById("btn_h").disabled = false;
          }, 1000);
        }
      })
      .catch((e) => {});
  };

  /////////////FOCUS //////////////
  const focusx = (e) => {
    e.target.select();
  };

  if (props.userd.username && FIContext.storeiv) {
    return (
      <div
        className={`  sai_main ${
          FIContext.storeiv === FIContext.rrt.store &&
          (FIContext.rrt.role === "sales" || FIContext.rrt.role === "accounts")
            ? null
            : "disabled"
        }`}
      >
        <div id="accordion">
          <div className="card">
            <div className="card-header si_rwiuevs ux_dhyru23s" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="sales-report-form"
                  data-toggle="collapse"
                  data-target="#ar2"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Accesorries Report Form
                </button>
              </h5>
            </div>

            <div
              id="ar2"
              className="collapse "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="div_group fi_iworis">
                  <div id="guu3" className="msgbar" style={{ visibility: vvs }}>
                    {errm}
                  </div>
                  <form>
                    <div className="reportfor">Report for {props.date}</div>
                    <div className="sales-form">
                      <div className="sales-first-col">
                        <div className="sales-input-container">
                          <div className="sales-text"> Ring Cases </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ringCases"
                            onFocus={focusx}
                            value={ringCases}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Silver Polish </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="silverPolish"
                            onFocus={focusx}
                            value={silverPolish}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Ring Snuggies</div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ringSunggies"
                            onFocus={focusx}
                            value={ringSunggies}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">Carrier Bags </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="carrierBags"
                            onFocus={focusx}
                            value={carrierBags}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Jewelry Cases</div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="jewelryCases"
                            onFocus={focusx}
                            value={jewelryCases}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                      <div className="sales-second-col">
                        <div className="sales-input-container">
                          <div className="sales-text">Velvet Cases</div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="velvetCases"
                            onFocus={focusx}
                            value={velvetCases}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Led Cases </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ledCases"
                            onFocus={focusx}
                            value={ledCases}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text">Jewelry Wipes</div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="jewelryWipes"
                            onFocus={focusx}
                            value={jewelryWipes}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="sales-hr" />

                    <div className="sialert" id="alert"></div>
                    <div className="sialert2" id="alert2"></div>
                    <div className="sales-cta">
                      <div className="sales-reset" id="btn_r" onClick={reset}>
                        <BiReset />
                        Reset
                      </div>
                      <div className="sales-reset" id="btn_h" onClick={save}>
                        <FiSend />
                        Submit
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default FormInput;
