import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../../Context";

import ButtonDropdown from "../Utils/ButtonDropdown";
import Axios from "axios";
import "./Viewer.css";
import Spinnerxx from "../Utils/Spinnerxx";
import YearDropdown from "../Utils/YearDropdown";

const Viewer = (props) => {
  const VVContext = useContext(AppContext);
  const [data, setdata] = useState([{}]);
  const [ddname, setddname] = useState("Select Month");
  const [reload, setreload] = useState(true);
  const [monthx, setMonthx] = useState(1);
  const [year, setYear] = useState(new Date().getFullYear().toString());

  const fetchdata = useCallback(async () => {
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/fetchaccessories/${monthx}/${year}/${VVContext.storeiv}`
    ).then((res) => {
      setdata(res.data);
    });
  }, [VVContext.storeiv, year, monthx]);

  useEffect(() => {
    let dd = new Date();
    let ddm = dd.getMonth() + 1;
    setMonthx(ddm);
  }, []);

  useEffect(() => {
    fetchdata();
  }, [reload, VVContext.storeiv, fetchdata]);

  const jump = (e) => {
    e.preventDefault();
    let id = parseInt(e.target.id);
    setMonthx(id);
    if (parseInt(id) === 1) {
      setddname("January");
    } else if (id === 2) {
      setddname("February");
    } else if (id === 3) {
      setddname("March");
    } else if (id === 4) {
      setddname("April");
    } else if (id === 5) {
      setddname("May");
    } else if (id === 6) {
      setddname("June");
    } else if (id === 7) {
      setddname("July");
    } else if (id === 8) {
      setddname("August");
    } else if (id === 9) {
      setddname("September");
    } else if (id === 10) {
      setddname("October");
    } else if (id === 11) {
      setddname("November");
    } else if (id === 12) {
      setddname("December");
    }
    // setdata({});
    // fetchdata(id);
  };

  if (props.userd.username && VVContext.storeiv) {
    return (
      <div className="sv_scrol">
        <div id="accordion">
          <div className="card">
            <div className="card-header si_rwiuevs ux_dhyru23s" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="sales-report-form"
                  data-toggle="collapse"
                  data-target="#pv"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Accesories Report Viewer - {VVContext.storeiv.toUpperCase()}
                </button>
              </h5>
            </div>

            <div
              id="pv"
              className="collapse "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <div className="pv_flex">
                          <div className="pv_ser"> </div>
                          <div>
                            <ButtonDropdown ddname={ddname} jump={jump} />
                          </div>
                          <YearDropdown setYearx={setYear} />
                        </div>
                      </div>
                    </div>
                    {data.data ? (
                      <div className="row">
                        <div className="col">
                          <div>
                            <table
                              style={{ fontSize: "11px" }}
                              className="table table-striped table-bordered pv_txt"
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Ring Cases</th>
                                  <th scope="col">Carrier Bags</th>
                                  <th scope="col">Led Cases</th>
                                  <th scope="col">Silver Polish </th>
                                  <th scope="col">Ring Snuggies</th>
                                  <th scope="col">Jewelry Cases</th>
                                  <th scope="col">Jewelry Wipes</th>
                                  <th scope="col">Velvet Cases</th>
                                  <th scope="col">Entry By</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.data.reverse().map((res) => {
                                  return (
                                    <tr key={res._id}>
                                      <th scope="row">{res.v_date}</th>
                                      <td>{res.v_ringCases}</td>
                                      <td>{res.v_carrierBags}</td>
                                      <td>{res.v_ledCases}</td>
                                      <td>{res.v_silverPolish}</td>
                                      <td>{res.v_ringSunggies}</td>
                                      <td>{res.v_jewelryCases}</td>
                                      <td>{res.v_jewelryWipes}</td>
                                      <td>{res.v_velvetCases}</td>
                                      <td>{res.v_entryby}</td>

                                      <td>{}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div> Loading</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default Viewer;
