import React from "react";
import "./UI.css";

const Input = ({ onChange, value, name, onBlur, type }) => {
  return (
    <div>
      <input
        type={type}
        className="weeklyr_input"
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        onMouseOut={onBlur}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Input;
