import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { AiFillPropertySafety } from "react-icons/ai";
import { AppContext } from "../../Context";
import { Spinner } from "react-bootstrap";
import { HiOutlineDocumentReport } from "react-icons/hi";

import "./Salesinput.css";
import Salesviewer from "./Salesviewer";
import { RiEyeCloseFill } from "react-icons/ri";
import { CgOpenCollective } from "react-icons/cg";
import { BiReset } from "react-icons/bi";
import { FiSend } from "react-icons/fi";

const Salesinput = (props) => {
  const SContext = useContext(AppContext);
  Axios.defaults.withCredentials = true;
  const [data, setdata] = useState([{}]);
  const [ndata, setndata] = useState([]);
  const [ringCases, setringCases] = useState(0);
  const [carrierBags, setcarrierBags] = useState(0);
  const [ledCases, setledCases] = useState(0);
  const [ringSunggies, setringSunggies] = useState(0);
  const [silverPolish, setsilverPolish] = useState(0);
  const [jewelryCases, setjewelryCases] = useState(0);
  const [jewelryWipes, setjewelryWipes] = useState(0);
  const [invoiceNumber, setinvoiceNumber] = useState();
  const [staffDuty, setstaffDuty] = useState();
  const [salesperson, setsalesperson] = useState("Delphi");
  const [amount, setamount] = useState(0);
  const [reload, setreload] = useState(false);
  const [txt, settxt] = useState("");
  const [showtxt, setshowtxt] = useState(false);
  const [newsales, setnewsales] = useState(false);

  useEffect(() => {
    getstaff();
  }, [props, SContext.storeiv]);

  const getstaff = async () => {
    let store = props.store;
    if (SContext.storeiv) {
      await Axios.get(
        `${process.env.REACT_APP_CLIENT_SERVER}/getstaff/${SContext.storeiv}`,
        { withCredentials: true }
      ).then((res) => {
        if (res) {
          setndata(res.data);
        }
      });
    }
  };

  const checkboxx = async (e) => {
    setnewsales(!newsales);
  };

  const changeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    if (!isNaN(value)) {
      setshowtxt(false);
      switch (id) {
        case "ringCases":
          setringCases(value);
          break;

        case "silverPolish":
          setsilverPolish(value);
          break;
        case "carrierBags":
          setcarrierBags(value);
          break;
        case "ledCases":
          setledCases(value);
          break;
        case "ringSunggies":
          setringSunggies(value);
          break;
        case "jewelryCases":
          setjewelryCases(value);
          break;
        case "jewelryWipes":
          setjewelryWipes(value);
          break;
        case "amount":
          setamount(value);
          break;

        default:
          break;
      }
    } else {
      if (id === "invoiceNumber") {
        setinvoiceNumber(value);
      } else if (id === "staffDuty") {
        setstaffDuty(value);
      } else if (id === "salesperson") {
        setsalesperson(value);
      } else {
        settxt("Values must be numbers only");
        setshowtxt(true);
      }
    }
  };

  const post = async () => {
    document.getElementById("btn_h").hidden = true;
    let dd = new Date();
    let ddm = dd.getMonth() + 1 + "/" + dd.getDate() + "/" + dd.getFullYear();
    if (
      invoiceNumber &&
      invoiceNumber.length >= 4 &&
      staffDuty &&
      staffDuty.length >= 3 &&
      amount >= 0
    ) {
      await Axios.post(`${process.env.REACT_APP_CLIENT_SERVER}/salespost`, {
        v_invoice: invoiceNumber,
        v_date: ddm,
        v_ringCases: ringCases,
        v_carrierBags: carrierBags,
        v_silverPolish: silverPolish,
        v_ringSunggies: ringSunggies,
        v_jewelryWipes: jewelryWipes,
        v_jewelryCases: jewelryCases,
        v_staffDuty: staffDuty,
        v_ledCases: ledCases,
        v_entryby: SContext.rrt.fname + " " + SContext.rrt.lname,
        store: SContext.storeiv,
        v_salesperson: salesperson,
        v_amount: amount,
        v_month: dd.getMonth() + 1,
        v_newsales: newsales,
      }).then((res) => {
        if (res.status === 200) {
          document.getElementById("alert").innerHTML = "Saving...";
          // document.getElementById("alert").style.backgroundColor =
          //   " rgb(255, 240, 240)";
          setTimeout(() => {
            reset();
            setreload(!reload);
            document.getElementById("btn_h").hidden = false;
            document.getElementById("alert").innerHTML = " ";
            document.getElementById("alert2").innerHTML = " ";
            // document.getElementById("alert").style.backgroundColor = "inherit";
          }, 1000);
        }
      });
    } else {
      document.getElementById("btn_h").hidden = false;
      document.getElementById("alert").innerHTML = "Some inputs are missing";
      document.getElementById("alert2").innerHTML =
        "Fields like Invoice number, Invoice Amount and Staff on duty are required";
      document.getElementById("alert").style.color = "red";
      document.getElementById("alert2").style.color = "red";
    }
  };
  const focus = (e) => {
    e.target.select();
  };

  const reset = (e) => {
    if (e) {
      e.preventDefault();
    }
    setinvoiceNumber("");
    setringCases(0);
    setcarrierBags(0);
    setledCases(0);
    setringSunggies(0);
    setsilverPolish(0);
    setjewelryCases(0);
    setjewelryWipes(0);
    setstaffDuty("");
    setsalesperson("Delphi");
    setamount(0);
  };

  if (props.userd.username && ndata.length > 0) {
    return (
      <div
        className={`container sai_main  ${
          SContext.storeiv === SContext.rrt.store &&
          SContext.rrt.role === "sales"
            ? null
            : "disabled"
        }`}
      >
        {/* <div className=" ch_eywi si_weoi ux_heeedds ux_uru77">Sales Report</div> */}
        <div className="si_boxy">
          <div id="accordion">
            <div className="card">
              <div
                className="card-header si_rwiuevs ux_dhyru23s uk_uk"
                id="headingOne"
              >
                <h5 className="mb-0">
                  <button
                    // className="btn btn-link btn_pvs uk_uk  "
                    className="sales-report-form"
                    data-toggle="collapse"
                    data-target="#ar2"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {/* <HiOutlineDocumentReport /> Sales report Form */}
                    Sales report form
                  </button>
                </h5>
              </div>

              <div
                id="ar2"
                className="collapse  "
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="div_group si_rwiuevs">
                    {/* <div id="guu3" className="msgbar" style={{ visibility: vvs }}>
                     
                    {errm}
                  </div> */}
                    <div className="reportfor">Report for {props.date}</div>
                    {showtxt && <div className="si_rowre"> {txt} </div>}

                    <div className="sales-form">
                      <div className="sales-first-col">
                        <div className="sales-input-container">
                          <div className="sales-text"> Invoice# </div>
                          <input
                            type="text"
                            className="sales-input"
                            required
                            minLength="7"
                            maxLength="8"
                            id="invoiceNumber"
                            onChange={changeHandler}
                            value={invoiceNumber}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Invoice Amount </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            id="amount"
                            onChange={changeHandler}
                            value={amount}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Ring Box(es) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ringCases"
                            onChange={changeHandler}
                            value={ringCases}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Carrier Bag(s) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="carrierBags"
                            onChange={changeHandler}
                            value={carrierBags}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Led Box(es) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ledCases"
                            onChange={changeHandler}
                            value={ledCases}
                            onFocus={focus}
                          />
                        </div>
                      </div>
                      <div className="sales-second-col">
                        <div className="sales-input-container">
                          <div className="sales-text"> Silver Wipe(s) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="silverPolish"
                            onChange={changeHandler}
                            value={silverPolish}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Jewelry Box(es) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="jewelryCases"
                            onChange={changeHandler}
                            value={jewelryCases}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Jewelry Wipe(s) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="jewelryWipes"
                            onChange={changeHandler}
                            value={jewelryWipes}
                            onFocus={focus}
                          />
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-text"> Ring Snuggie(s) </div>
                          <input
                            type="number"
                            className="sales-input"
                            required
                            minLength="1"
                            maxLength="4"
                            id="ringSunggies"
                            onChange={changeHandler}
                            value={ringSunggies}
                            onFocus={focus}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="sales-hr" />
                    <div className="sales-form">
                      <div className="sales-first-col">
                        <div className="sales-input-container">
                          <div className="sales-text"> Sales Person </div>
                          <select
                            name="cars"
                            id="salesperson"
                            className="sales-person-dropdown"
                            onChange={changeHandler}
                          >
                            {/* <option
                              value="delphi"
                              className="sales-person-option"
                            >
                              Delphi
                            </option> */}

                            {ndata.map((res, i) => {
                              return (
                                <option
                                  value={res}
                                  key={i}
                                  className="sales-person-option"
                                >
                                  {res}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="sales-input-container">
                          <div className="sales-checkbox">
                            <input
                              type="checkbox"
                              id="checkpp"
                              className="sales-checkbox-input"
                              onClick={checkboxx}
                            />
                            New invoice
                          </div>
                        </div>
                      </div>
                      <div className="sales-second-col">
                        <div className="sales-input-container">
                          <div className="sales-text">Staff on duty </div>
                          <input
                            type="text"
                            className="sales-input"
                            required
                            id="staffDuty"
                            onChange={changeHandler}
                            value={staffDuty}
                            onFocus={focus}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sialert" id="alert"></div>
                    <div className="sialert2" id="alert2"></div>
                    <div className="sales-cta">
                      <div className="sales-reset" id="btn_r" onClick={reset}>
                        <BiReset />
                        Reset
                      </div>
                      <div className="sales-reset" id="btn_h" onClick={post}>
                        <FiSend /> Submit
                      </div>
                    </div>

                    {/* <div className="si_duw99">
                      <div className="si_sdwee11 ">
                        Invoice Number: 
                        <input
                          type="text"
                          className="form-control  si_in input_trans si_ieu88"
                          required
                          minLength="7"
                          maxLength="8"
                          id="invoiceNumber"
                          onChange={changeHandler}
                          value={invoiceNumber}
                          onFocus={focus}
                        />
                      </div>
                      <div className="si_sdwee11 ">
                        Invoice Amount: 
                        <input
                          type="text"
                          className="form-control  si_in input_trans si_ieu88"
                          required
                          id="amount"
                          onChange={changeHandler}
                          value={amount}
                          onFocus={focus}
                        />
                        <div className="si_sdwee11 ">
                          <input
                            type="checkbox"
                            id="checkpp"
                            className="si_djhw722"
                            onClick={checkboxx}
                          />
                          {"   "}New invoice
                        </div>
                      </div>
                    </div>

                    <form>
                      <div className="form-group si_rwiuevd">
                        <div className="form-row" id="row3">
                          <div className="col">
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevd">
                                <div className="width_p">
                                  <div className="text-uppercase text-xs mb-1 ">
                                    <span>Ring Cases Used: </span>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="ringCases"
                                  onChange={changeHandler}
                                  value={ringCases}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                  <div className="text-uppercase    text-xs mb-1">
                                    <span>Silver Polish Used:</span>
                                  </div>
                                   
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="silverPolish"
                                  onChange={changeHandler}
                                  value={silverPolish}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                   
                                  <div className="text-uppercase    text-xs mb-1">
                                    <span>Carrier Bags Used:</span>
                                  </div>
                                   
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="carrierBags"
                                  onChange={changeHandler}
                                  value={carrierBags}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                  <div className="text-uppercase    text-xs mb-1">
                                    <span>Jewelry Cases Used:</span>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="jewelryCases"
                                  onChange={changeHandler}
                                  value={jewelryCases}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                  <div className="text-uppercase    text-xs mb-1">
                                    <span>Led Cases Used:</span>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="ledCases"
                                  onChange={changeHandler}
                                  value={ledCases}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                  <div className="text-uppercase    text-xs mb-1">
                                    <span>Jewelry Wipes Used:</span>
                                  </div>
                                   
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="jewelryWipes"
                                  onChange={changeHandler}
                                  value={jewelryWipes}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-row fgn2">
                              <div className="col gh_flex ggn si_rwiuevs">
                                <div className="width_p">
                                  <div className="text-uppercase   text-xs mb-1">
                                    <span>Ring Snuggies Used: </span>
                                  </div>
                                   
                                </div>
                                <input
                                  type="text"
                                  className="form-control tty input_trans"
                                  required
                                  minLength="1"
                                  maxLength="4"
                                  id="ringSunggies"
                                  onChange={changeHandler}
                                  value={ringSunggies}
                                  onFocus={focus}
                                />
                              </div>
                            </div>
                            <div className="sialert" id="alert">
                               
                            </div>
                          </div>
                        </div>

                        <div style={{ marginBottom: "10px" }} className="si_cn">
                          Sales Person: 
                          <select
                            name="cars"
                            id="salesperson"
                            className="si_irw992"
                            onChange={changeHandler}
                          >
                            <option value="delphi">Delphi</option>

                            {ndata.map((res, i) => {
                              return (
                                <option value={res} key={i}>
                                  {res}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ marginBottom: "10px" }} className="si_cn">
                          Staff On Duty: 
                          <input
                            type="text"
                            className="form-control  si_in input_trans"
                            required
                            id="staffDuty"
                            onChange={changeHandler}
                            value={staffDuty}
                            onFocus={focus}
                          />
                        </div>

                        <div className="form-row">
                          <div className="col">
                            <div className="div_btn">
                              <button
                                className="btn btn-secondary    ux_jjhe8839bss ukbtn  "
                                type="button"
                                id="btn_r"
                                onClick={reset}
                              >
                                Reset
                              </button>
                              <button
                                className="btn ux_jjhe8839b ukbtn"
                                type="button"
                                id="btn_h"
                                onClick={post}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="spinnerxd"></div>;
  }
};

export default Salesinput;
