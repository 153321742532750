import { useState, useEffect, useCallback } from "react";
import Axios from "axios";

const useGetInvoice = (invoiceNumber) => {
  const [data, setData] = useState();

  const fetchInvoices = useCallback(async () => {
    return await Axios.post(
      `${process.env.REACT_APP_SERVER}/getinvoiceql`,
      {
        query: `
        query GetInvoice($args: InvoiceArgs) {
            getInvoice(args: $args) {
              invoiceDate
              invoiceNumber
              invoiceStatus
              customerName
              taxInclusive
              currencyCode
              discountPercent
              subTotal
              total
              balance
              adjustment
              lastPaymentDate
              discountAmount
              branchName
              itemName
              quantity
              shippingAddress
              sku
              email
              phone
              itemDesc
              itemTax
              taxPercent
              taxAmount
              cfStatus
            }
        }
            `,
        variables: {
          args: {
            invoiceNumber: invoiceNumber.toUpperCase(),
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [invoiceNumber]);

  useEffect(() => {
    fetchInvoices()
      .then((res) => {
        if (res) {
          const tempData = res.data.data.getInvoice.map((item, i) => {
            return {
              ...item,
              id: i,
            };
          });

          const newData = {
            data: tempData,
          };

          setData(newData);
        }
      })
      .catch((e) => console.log(e));
  }, [fetchInvoices]);

  return {
    data,
  };
};

export default useGetInvoice;
