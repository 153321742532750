import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import "../UI/Input";
import Input from "../UI/Input";
import Button from "../UI/Button";
import "./QA.css";

const QA = ({
  question,
  multiple,
  setstate,
  state: statex,
  codename,
  required,
  type,
}) => {
  const [state, setState] = useState([
    {
      codename,
      name: "Q0",
      answer: "",
      index: 0,
    },
  ]);
  const [count, setCount] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [fake, setFake] = useState(true);

  useEffect(() => {
    if (statex[[codename]] && fake) {
      setState(statex[[codename]]);
      setCount(statex[[codename]].length - 1);
      setFake(false);
    }
  }, [statex, codename, fake, setFake]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    let arr = [...state];
    let sarr = arr.filter((res) => res.name === name);
    let index = arr.indexOf(...sarr);
    arr[index]["answer"] = value;

    setState(arr);
  };

  const focusOff = () => {
    let stateobj = { ...statex };
    if (stateobj) {
      stateobj[[codename]] = state;
      setstate(stateobj);
    }
  };

  const updateFocusState = useCallback(() => {
    if (state && buttonClicked) {
      let stateobj = { ...statex };
      stateobj[[codename]] = state;
      setstate(stateobj);
      setButtonClicked(false);
    }
  }, [state, buttonClicked, codename, statex, setstate]);

  useEffect(() => {
    updateFocusState();
  }, [updateFocusState]);

  return (
    <div className="weeklyr_majordv">
      <div className="qa_question">
        <div className="weeklyr_question2">
          {question}
          {!required && <span className="weekly_optional"> (Optional) </span>}
        </div>
        {state &&
          state.map((res, i) => {
            return (
              <div key={`${res.name}-tt${i}`}>
                <Input
                  name={res?.name}
                  value={res?.answer}
                  onChange={changeHandler}
                  onBlur={focusOff}
                  type={type}
                />
              </div>
            );
          })}
      </div>

      <div className="Button_Div">
        {multiple && (
          <>
            <Button
              onClick={() => {
                let arr = [...state];
                if (arr?.length > 0) {
                  if (arr?.length < 10 && arr[count]["answer"] !== "") {
                    setButtonClicked(true);
                    arr?.push({
                      codename,
                      name: `Q${count + 1}`,
                      answer: "",
                      index: count + 1,
                    });
                    setState(arr);
                    setCount(count + 1);
                    updateFocusState();
                  }
                }
              }}
              type="plus"
            />
            {state.length !== 1 && (
              <Button
                onClick={() => {
                  setButtonClicked(true);
                  let arr = [...state];
                  if (arr.length >= 1) {
                    arr.pop();
                    setState(arr);
                    setCount(count - 1);
                    updateFocusState();
                  }
                }}
                type="minus"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QA;
