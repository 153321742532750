import React, { useState } from "react";

import "./ResetPwd.css";
const ResetPwd = () => {
  const [email, setemail] = useState("");

  const changeHandler = (e) => {
    let value = e.target.value;
    setemail(value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="container rs_jfk1">
        <div className="row">
          <div className="col rs_jdje822"> Reset Your Password</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="rs_00sdsdf">
              If you have forgotten your password you can reset it.
            </div>
            <div className="rs_e3i4">
              <span>
                Please enter the email address you used to register for online
                access then click Email me. We will send you further
                instructions.
              </span>
              <div className=" rs_93jdj">
                <div className="">
                  <form
                    className="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-xl-center rs_iuru8"
                    onSubmit={submitHandler}
                  >
                    <div className=" rs_skle9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        id="rpwd"
                        value={email}
                        onChange={changeHandler}
                      />
                      <button className="btn   rs_jd8372" type="submit">
                        Email me
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPwd;
