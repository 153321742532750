import React from "react";
import "./MainPage.css";

const MainPage = () => {
  const title = [
    "Warranty and Guarantee Policy",
    "Delphi Metals Employee Policies and Handbook",
    "Delphi Metals Handbook Acknowledgment form",
    "Employee Non-Disclosure Agreement",
    "Addendum: Repair Policy",
    "Addendum: Custom Order Policy 2",
    "Sales Target - Nigeria",
  ];

  const links = [
    "/Files/WARRANTY AND GUARANTEE POLICY.pdf",
    "/Files/DELPHI METALS HANDBOOK.pdf",
    "/Files/DELPHI METALS HANDBOOK Acknowledge.pdf",
    "/Files/Employee Non-Disclosure Agreement.pdf",
    "/Files/addendum-repair-policy.pdf",
    "/Files/addendum-custom-order-policy2.pdf",
    "/Files/Sales Target-Nigeria.pdf",
  ];

  return (
    <div>
      <h1 className="psd_mainheader"> Policies and Documents </h1>

      <div className="psd_container">
        {title.map((res, i) => {
          return (
            <div className="psd_subcontainer" key={i}>
              <div className="psd_policytitle"> {res} </div>
              <div className="psd_downloadlink">
                <a href={links[i]} target="_blank" rel="noreferrer">
                  <button className="psd_btn_policy_documents"> View </button>
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div> </div>
    </div>
  );
};

export default MainPage;
