import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import Axios from "axios";
import FormViewer from "./FormViewer";
import {
  ButtonFormPane,
  FindText,
  FormPaneContainer,
  InputFormPane,
  InputWrapper,
} from "./styles";

const FormPaneV2 = (props) => {
  const [ddt, setddt] = useState();
  const [search, setsearch] = useState("");
  const qs = queryString?.parse(window?.location?.search);

  const id = qs?.["id"]?.toLowerCase();

  const fextData = useCallback(async () => {
    let ss = search.replace(/\s/g, "");
    await Axios.post(`${process.env.REACT_APP_SERVER}/searchorder`, {
      orderId: ss,
    })
      .then((res) => {
        if (res) {
          console.log(res);
          setddt(res.data.data);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [search]);

  useEffect(() => {
    if (id) {
      setsearch(id);
      fextData();
    }
  }, [id, fextData]);

  return (
    <FormPaneContainer>
      <FindText>Find & Track Your Order</FindText>
      <InputWrapper>
        <InputFormPane
          type="text"
          placeholder="Input your invoice number & your Last Name"
          value={search}
          onChange={(e) => setsearch(e.target.value.toLowerCase())}
        />
        <ButtonFormPane onClick={fextData}> Track </ButtonFormPane>
      </InputWrapper>
      <FormViewer data={ddt} mdata={props.data} />
    </FormPaneContainer>
  );
};

export default FormPaneV2;
