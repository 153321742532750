import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Drawer } from "antd";
import useGetReservedDeals from "../Hooks/useGetReservedDeals";
import {
  DealSearchContainer,
  ExpandDealBtn,
  InputSearch,
  ResetTable,
  SearchWrapper,
  TableWrapper,
} from "./DealsSearch.style";
import { convertDate, currencySymbolConversion } from "./helpers";
import DealsDrawer from "./DealsDrawer";
import useGetReservedDealsById from "../Hooks/useGetReservedDealsById";
import Loading from "../components/Loading";

const DealsSearch = ({ onCancel }) => {
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [drawerData, setDrawerData] = useState({});
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");

  const limit = 10;

  const { data } = useGetReservedDeals(limit, offset);
  const { data: data2 } = useGetReservedDealsById(search);

  const showDrawer = (row) => {
    setOpen(true);
    setDrawerData(row);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data2 && search) {
      if (!data2.data[0].reference) {
        setRowCountState(data2.count);
        setLoading(false);
        setTableData([]);
      } else {
        setRowCountState(data2.count);
        setLoading(false);
        setTableData(() =>
          data2.data.map((res, i) => {
            return {
              ...res,
              id: `${res.id}-${i}`,
            };
          })
        );
      }
    } else if (data) {
      setRowCountState(data.count);
      setLoading(false);
      setTableData(() =>
        data.data.map((res, i) => {
          return {
            ...res,
            id: `${res.id}-${i}`,
          };
        })
      );
    }
  }, [data, data2, search]);

  const columns = [
    {
      flex: 1,
      field: "id",
      headerName: "Order Id",
    },
    {
      flex: 1,
      field: "fname",
      headerName: "First Name",
    },
    {
      flex: 1,
      field: "lname",
      headerName: "Last Name",
    },
    {
      flex: 1,
      field: "itemPrice",
      headerName: "Item Price",
      renderCell({ row }) {
        return (
          <div style={{ textDecoration: "line-through" }}>
            {" "}
            {currencySymbolConversion(
              row.itemPrice,
              row.currencyCode,
              row.status
            )}{" "}
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "dealPrice",
      headerName: "Deal Price",
      renderCell({ row }) {
        return (
          <div>
            {" "}
            {currencySymbolConversion(
              row.dealPrice,
              row.currencyCode,
              row.status
            )}{" "}
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
    },
    {
      flex: 1,
      field: "orderDate",
      headerName: "Order Date",
      renderCell({ row }) {
        return <div>{convertDate(row.orderDate)}</div>;
      },
    },
    {
      flex: 1,
      field: "expiryDate",
      headerName: "Expiry Date",
      renderCell({ row }) {
        if (row.status === "Ordering") {
          return <div>{convertDate(row.expiryDate)}</div>;
        }
        return "N/A";
      },
    },
    {
      flex: 0.8,
      headerName: "Expand",
      renderCell({ row }) {
        return (
          <>
            <ExpandDealBtn onClick={() => showDrawer(row)}>
              {" "}
              View{" "}
            </ExpandDealBtn>
          </>
        );
      },
    },
  ];

  const onSearch = (value) => {
    setSearch(value.toString());
  };

  if (data) {
    return (
      <DealSearchContainer>
        <SearchWrapper>
          <InputSearch
            placeholder="Search for specific Order Id"
            onSearch={onSearch}
            style={{ width: 400 }}
            value={tempSearch}
            onChange={(e) => setTempSearch(e.target.value)}
          />
          <ResetTable
            onClick={() => {
              setTempSearch("");
              setSearch("");
            }}
          >
            Reset Table
          </ResetTable>
        </SearchWrapper>
        <TableWrapper>
          <DataGrid
            rows={tableData}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5]}
            pagination
            page={page}
            pageSize={limit}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage);
              setOffset(() => {
                if (newPage === 0) {
                  return 0;
                }
                return newPage * 10;
              });
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            //   initialState={initialState}
          />
        </TableWrapper>
        <Drawer
          title="Deal Details"
          placement="right"
          onClose={onClose}
          open={open}
          width={400}
          zIndex={399999}
        >
          <DealsDrawer data={drawerData} />
        </Drawer>
      </DealSearchContainer>
    );
  }
  return (
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      {" "}
      <Loading size="large" />{" "}
    </div>
  );
};

export default DealsSearch;
