import styled from "styled-components";

export const MainContainer = styled.div``;

export const TableContainer = styled.div`
  height: 1000px;
`;

export const InvoiceDrawerWrapper = styled.div`
  font-size: 14px;
`;

export const IWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const Key = styled.div`
  font-weight: 500;
  color: darkblue;
`;
export const Value = styled.div`
  margin-left: 10px;
  color: grey;
`;

export const Line = styled.hr``;
