import React from "react";
import Axios from "axios";
import "./Salesdelete.css";
const Salesdelete = (props) => {
  Axios.defaults.withCredentials = true;
  const cancel = () => {
    props.cancel();
  };

  const submit = async (e) => {
    e.preventDefault();
    await Axios.post(
      `${process.env.REACT_APP_CLIENT_SERVER}/salesdelete`,

      {
        id: props.id,
        store: props.store,
      }
    )
      .then((res) => {
        if (res) {
          props.cancel();
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="sales-delete">
          <div className="se_div1 se_divflexd sd_three">
            <div className="sd_twoa">
              <p>Are you sure you want to delete this entry? </p>
            </div>
            <div className="se_brrr">
              {/* <button
                className="btn btn-primary se_btnyy ukbtn"
                type="button"
                onClick={cancel}
              >
                X
              </button> */}
            </div>
          </div>
          <div className="sales_delete_cta">
            <div className="sales-reset" type="button" onClick={submit}>
              Yes 
            </div>
            <div className="sales-reset" type="button" onClick={cancel}>
              No
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salesdelete;
