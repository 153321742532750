import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./EditUsers.css";
import Spinnerxx from "../Utils/Spinnerxx";

const EditUsers = (props) => {
  Axios.defaults.withCredentials = true;
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [email, setemail] = useState();
  const [store, setstore] = useState("Select Store");
  const [role, setrole] = useState("Select Role");
  const [err, seterr] = useState(false);
  const [txt, settxt] = useState("Form not filled properly");
  const [ready, setready] = useState(false);
  const [snum, setsnum] = useState("");
  const [ddd, setddd] = useState({});

  useEffect(() => {
    setddd({});
    getdat(props.id);
  }, [props.id, err]);

  useEffect(() => {
    if (fname && lname) {
      if (store !== "Select Store" && role !== "Select Role") {
        var emailCheck =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
        if (emailCheck.test(email)) {
          setready(true);
        }
      }
    }
  }, [fname, lname, store, role, email, snum]);

  const getdat = async (id) => {
    await Axios.get(`${process.env.REACT_APP_CLIENT_SERVER}/getuser/${id}`)
      .then((res) => {
        setddd(res.data);
        setfname(res.data[0].fname);
        setlname(res.data[0].lname);
        setemail(res.data[0].email);
        setstore(res.data[0].store);
        setrole(res.data[0].role);
        setsnum(res.data[0].snum);
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
    setunits();
    // console.log(ddd[0].email);
  };

  const setunits = () => {};

  const AUsubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      fname: fname,
      lname: lname,
      email: email,
      store: store,
      role: role,
      snum: snum,
      id: props.id,
    };
    if (ready) {
      seterr(false);
      await Axios.put(`${process.env.REACT_APP_CLIENT_SERVER}/editusers`, {
        data,
      })
        .then((res) => {
          settxt("User Added");
          seterr(true);
          props.refresh();
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    } else {
      seterr(true);
    }
  };

  const AUchangeHandler = (e) => {
    e.preventDefault();
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "snum":
        setsnum(value);
        break;
      case "fname":
        setfname(value);
        break;
      case "lname":
        setlname(value);
        break;

      case "yaba":
        setstore("yaba");
        break;
      case "abuja":
        setstore("abuja");
        break;
      case "lekki":
        setstore("lekki");
        break;
      case "accra":
        setstore("accra");
        break;
      case "sales":
        setrole("sales");
        break;
      case "supervisor":
        setrole("supervisor");
        break;
      case "accounts":
        setrole("accounts");
        break;
      case "admin":
        setrole("admin");
        break;

      default:
        break;
    }
  };

  if (ddd.length > 0) {
    return (
      <div className="container ">
        <div className="se_divcc">
          {err ? <div className="au_txt eu_eue">{txt}</div> : null}
          <div className="se_div1 se_divflex" style={{ marginBottom: "33px" }}>
            <div className="se_brrr" style={{ marginLeft: " " }}>
              <button
                className="btn btn-primary se_btnyy"
                type="button"
                onClick={props.cancel}
              >
                X
              </button>
            </div>
          </div>
          <div className="d-lg-flex d-xl-flex justify-content-md-center justify-content-lg-center justify-content-xl-center se_divgroup">
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>First Name</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="se_tin22"
                    id="fname"
                    value={fname}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>Last Name</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="se_tin22"
                    id="lname"
                    value={lname}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>Email</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="se_tin22"
                    value={email}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-flex d-xl-flex justify-content-md-center justify-content-lg-center justify-content-xl-center">
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>Staff Number</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="se_tin22"
                    id="snum"
                    value={snum}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>Store</label>
                </div>
                <div>
                  <div className="dropdown">
                    <button
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {store.toUpperCase()}
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        href="/"
                        id="yaba"
                        onClick={AUchangeHandler}
                      >
                        Yaba Store
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="abuja"
                        onClick={AUchangeHandler}
                      >
                        Abuja Store
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="lekki"
                        onClick={AUchangeHandler}
                      >
                        Lekki Store
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="accra"
                        onClick={AUchangeHandler}
                      >
                        Accra Store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="se_div2">
              <div className="justify-content-center se_divflex">
                <div className="se_inp2">
                  <label>Role</label>
                </div>
                <div>
                  <div className="dropdown">
                    <button
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {role.toUpperCase()}
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        href="/"
                        id="sales"
                        onClick={AUchangeHandler}
                      >
                        Sales Representative
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="supervisor"
                        onClick={AUchangeHandler}
                      >
                        Supervisor
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="accounts"
                        onClick={AUchangeHandler}
                      >
                        Accounts
                      </a>
                      <a
                        className="dropdown-item"
                        href="/"
                        id="admin"
                        onClick={AUchangeHandler}
                      >
                        Admin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="se_btt2">
            <button
              className="btn btn-primary"
              type="button"
              onClick={AUsubmitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin"> Loading</div>
      </div>
    );
  }
};

export default EditUsers;
