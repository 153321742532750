import React, { useState, useEffect, useMemo } from "react";
import { Progress } from "reactstrap";
import querystring from "query-string";
import { BiRightArrowCircle } from "react-icons/bi";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
 


import Axios from "axios";
import "./Perform.css";


 

const Perform = () => {
  const qs = querystring.parse(window.location.search);
  const [store, setstore] = useState(qs.store || "yaba");
  const [storex, setstorex] = useState(qs.store || "yaba");
  const [activeIndex, setActiveIndex] = useState(0)
  const [month, setmonth] = useState(new Date().getMonth() + 1);
  const [stafflist, setstafflist] = useState([]);
  const [staffInFocus, setStaffInFocus] = useState( )
  const [staffDetails, setStaffDetails] = useState() 
  const [isStaffDisplayGraph, setIsStaffDisplayGraph] = useState(false)
  const [refresh, setRefresh] = useState(true)

  const color = ["primary", "danger", "info", "warning", "success"];

  const pColor = ["navy", "#880808" ,"green"  ,"orange" ,"black" ,"brown"]

  useEffect(() => {
   
      getstaff();
   
  
  }, [ ]);

  useEffect(() => {
    setStaffInFocus(staffInFocus ?? stafflist[0]?.name)
  },[stafflist])

  useEffect(() => {

    const getStaffDetails = async () => {
 
      await Axios.get(
        `${process.env.REACT_APP_CLIENT_SERVER}/performance-history/${store}/${staffInFocus}/${month}`
      ).then((res) => {
        if (res) {
          setStaffDetails(res.data.data.reverse());
        }
      }).finally(() => {
        setRefresh(false)
      })
    }
    
if(staffInFocus || refresh) {
  getStaffDetails()
}   
  } , [staffInFocus,refresh])

  const currency = storex === "accra" ? "C" : "N"

  const getstaff = async () => {
   setRefresh(!refresh)
    setstorex(store);
    await Axios.get(
      `${process.env.REACT_APP_CLIENT_SERVER}/indsalesx/${store}/${month}`
    ).then((res) => {
      if (res) {
        setstafflist(res.data.data);
      }
    })
  };

  const newStaffList = useMemo(() => {
    return stafflist.map((item, index) => {
      const totalBranchSale = stafflist.reduce((acc, item) => acc + item.amount, 0);
      return { ...item, value: item.amount, totalBranchSale };
    });
  },[stafflist]);

  const handleStaffDisplay = () => {
   setIsStaffDisplayGraph(!isStaffDisplayGraph)

  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, bonusEarned , totalBranchSale} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Total: ${currency}${value.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Branch Total Sale: ${currency}${totalBranchSale.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={38} textAnchor={textAnchor} fill="#999">
          {`(Special Bonus Earned: ${currency}${bonusEarned.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
        </text>
      </g>
    );
  };
 

  const changemonth = (e) => {
    e.preventDefault();
    setmonth(parseInt(e.target.value));
  };

  const changestore = (e) => {
    e.preventDefault();
    setstore(e.target.value);
  };

  return (
    <div className="container sv_scrol">
      <div className="row">
        <div className="col d-flex pe_jjhhru22 ">
          <div className="pe_kd823">
            <select onChange={changestore} id="storec" style={{padding: "5px"}}>
              <option value="yaba"> Yaba</option>
              <option value="abuja"> Abuja</option> 
              <option value="accra"> Accra</option>
            </select>
          </div>
          <div className="pe_kd823">
            <select onChange={changemonth} id="monthchange"  style={{padding: "5px"}}>
              <option value="" selected disabled hidden>
                Select month
              </option>
              <option value="1"> January</option>
              <option value="2"> February</option>
              <option value="3"> March</option>
              <option value="4"> April</option>
              <option value="5"> May</option>
              <option value="6"> June</option>
              <option value="7"> July</option>
              <option value="8"> August</option>
              <option value="9"> September</option>
              <option value="10"> October</option>
              <option value="11"> November</option>
              <option value="12"> December</option>
            </select>
          </div>
          <div>
            <button className="pe_btn" type="button" onClick={getstaff}>
              Show report
            </button>
          </div>
        </div>
      </div>
      <div  >
        <div className="col pe_di89"  >
       {newStaffList && newStaffList.length > 0 ?
       <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}> 
        <PieChart width={1000} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={newStaffList}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={(props,index) =>{ setActiveIndex(index)  
            setStaffInFocus(props.name)
            }}
          > 
          {newStaffList.map((pie,index) => (
          <Cell key={`cell-${index}`} fill={pColor[index]} />
          ))}
          </Pie>
        </PieChart>

      

        </div>
         : <div>No data </div> }  

           <div style={{display: "flex", justifyContent:"center", alignItems:"center", marginBottom: "20px"}}> 
        {newStaffList.map((pie,index) => (
          <div style={{display: "flex", alignItems:"center", marginRight: "20px"}}> 
            <div style={{background: pColor[index], width: "16px", height: "16px" , marginRight:"8px" }}> </div>
            <div style={{fontWeight: activeIndex === index ? "bold" : "normal" , 
            color: activeIndex === index ? "black" : "grey"
            }}> {pie.name} </div>
          </div> 
          ))}
        </div>

         {staffDetails && staffDetails.length > 0 ?   
         <div> 
          <StaffDetails staffDetails={staffDetails} currency={currency} displayGraph= {isStaffDisplayGraph} staffInFocus={staffInFocus} /> 
          <div className="staff-details-cta--wrapper"> 
              <button className="pe_btn" onClick={handleStaffDisplay}> {isStaffDisplayGraph ? "Hide" : "Show"} Graph</button>
          </div>
          </div>
            : null}
        
        </div>
      </div>
    </div>
  );
};


const StaffDetails = ({staffDetails, currency,displayGraph,staffInFocus}) => {


  if(displayGraph) {
   return ( 
        <LineChart
          width={1000}
          height={400}
          data={staffDetails}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis dataKey="amount" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="amount" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="bonusEarned" stroke="#82ca9d" />
        </LineChart>
   )

  }

  return (

    <div> 
    <div style={{textAlign:"center", padding: "10px", marginBottom: "16px"}}> 
    Last 6 months Performance report for <span style={{fontWeight:"bold"}}> {staffInFocus} </span> 
    </div>
    <div className="staff-details-header"   >  
    <div  className="  staff-details-bold"> Month </div>
    <div  className="  staff-details-bold"> Amount </div>
    <div  className="  staff-details-bold"> Special Bonus Earned </div>
    {/* <div  className="  staff-details-bold"> Bonus For Admin </div> */}
    </div>
    {staffDetails && staffDetails.length > 0&& staffDetails.map((staff, index) => {
      return (
        <div className="staff-details-header" > 
        <div className="staff-details-cell "> {staff.month} </div>
    <div  className="staff-details-cell "> {currency} {staff.amount.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
    <div  className="staff-details-cell "> {currency} {staff.bonusEarned.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
    {/* <div  className="staff-details-cell "> {currency} {staff.bonusForAdminStaff.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div> */}
        </div>
      )
    })}
    </div>
  )
}

export default Perform;
