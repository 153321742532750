import React, { useState, useContext } from "react";
import { AppContext } from "../../Context";
import { useHistory } from "react-router-dom";
import { AiOutlineLogin } from "react-icons/ai";
import { GiDiamondRing } from "react-icons/gi";
import Axios from "axios";
import Cryptr from "cryptr";

import "./Login.css";
import { SE } from "../Constants";
import { useEffect } from "react";
import Loading from "../Loading";

const Login = (props) => {
  const LGContext = useContext(AppContext);
  const history = useHistory();
  Axios.defaults.withCredentials = true;
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [puser, setpuser] = useState("");
  const [logbtn, setlogbtn] = useState("Log in");
  const [showerr, setshowerr] = useState("hidden");
  const [loading, setLoading] = useState(false);
  const cryptr = new Cryptr(SE);

  const LOchangeHandler = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "username":
        setemail(value);
        break;
      case "password":
        setpassword(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (loading) {
      LOsubmit();
    }
  }, [loading]);

  const LOsubmit = async () => {
    const newpw = cryptr.encrypt(password);

    await Axios({
      // url: `${process.env.REACT_APP_CLIENT_SERVER}/login`,
      url: `${process.env.REACT_APP_CLIENT_SERVER}/login`,
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      auth: {
        username: email,
        password: newpw,
      },
    }).then((res) => {
      setpuser(res.data.username);

      props.ln("Log Out");
      props.seee(res.data);
      if (res.data.status === 404) {
        setshowerr("show");
        setemail("");
        setpassword("");
      }
    });
  };

  if (props.userd.username) {
    history.push("/salesreport");

    return <div> </div>;
  } else {
    return (
      <div>
        <div className="login-clean">
          <form>
            <h2 className="sr-only">Login Form</h2>
            <div className="illustration uk_uk">
              <div className=" ">
                <GiDiamondRing />
              </div>
              <div className={`lg_inv ${showerr}`}>
                Invalid Username or Password
              </div>
            </div>
            <div className="form-groupxx">
              <input
                type="email"
                className="form-controlx"
                name="username"
                placeholder="Email"
                id="username"
                onChange={LOchangeHandler}
                value={email}
              />
            </div>
            <div className="form-groupxx">
              <input
                type="password"
                className="form-controlx"
                name="password"
                placeholder="Password"
                id="password"
                onChange={LOchangeHandler}
                value={password}
              />
            </div>
            {!!loading && (
              <div
                style={{
                  textAlign: "center",
                  position: "relative",
                  top: "20px",
                }}
              >
                <Loading size={"large"} />
              </div>
            )}
            <div className="form-groupxx">
              <button
                className="lg_btn_btn  "
                type="button"
                disabled={loading}
                onClick={(e) => {
                  setLoading(true);
                }}
              >
                <AiOutlineLogin className="lg_icon_lg" /> {logbtn}
              </button>
            </div>
            <a className="forgot" href="/">
              Forgot your password?
            </a>
          </form>
        </div>
      </div>
    );
  }
};

export default Login;
