import React from "react";
import InputStock from "./InputStock";
import "./Stock.css";
import ViewStock from "./ViewStock";

const Stock = (props) => {
  return (
    <div className="container">
      {/* <div className="ch_eywi si_weoi ux_heeedds ux_uru777"> Stock Report </div> */}
      <InputStock
        userd={props.userd}
        data={props.data}
        store={props.store}
        date={props.date}
      />
      <ViewStock userd={props.userd} data={props.data} store={props.store} />
    </div>
  );
};

export default Stock;
