import React from "react";
import Nav1 from "../../components/Accessories/Nav1";
import Login from "../../components/Login/Login";
import "./MainHome.css";

const MainHome = ({ datt, rrt, lg, seee, lgbt }) => {
  return (
    <div>
      <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
      <Login seee={seee} userd={datt} ln={lgbt} />
    </div>
  );
};

export default MainHome;
