import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import Nav1 from "./components/Accessories/Nav1";
import { AppContext } from "./Context";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Mainaccesories from "./components/Accessories/Main";
import Stock from "./components/Stock/Stock";
import Pettycash from "./components/Pettycash/Pettycash";
import Salesinput from "./components/Sales/Salesinput";
import AddUsers from "./components/Admin/AddUsers";
import Axios from "axios";
import Welcome from "./components/Utils/Welcome";
import Profile from "./components/Admin/Profile";
import Policies from "./Add-ons/Policies";
import Distro from "./Add-ons/Distro/MainPage";
import Users from "./components/Admin/Users";
import ChangePassword from "./components/Admin/ChangePassword";
import Salesviewer from "./components/Sales/Salesviewer";
import Pettyviewer from "./components/Pettycash/Pettyviewer";
import Spinnerxx from "./components/Utils/Spinnerxx";
import ResetPwd from "./components/Admin/ResetPwd";
import CompletedOrders from "./Trackit/Components/AdminComp/CompletedOrders";
import NewOrder from "./Trackit/Components/AdminComp/NewOrder";
import UpdateOrders from "./Trackit/Components/AdminComp/UpdateOrders";
import AdminPanel from "./Trackit/Components/AdminPanel.js";
import Footer from "./Footer.js";
import useGetDaa from "./Hooks/useGetDaa";
import Progressx from "./components/Performance/Progressx.js";
import Perform from "./components/Performance/Perform";
import MainHome from "./Pages/MainHome/MainHome";
import MainPage from "./Add-ons/WeeklyReport/Page/MainPage";
import FormPaneV2 from "./Trackit/Components/FormPaneV2";

function App() {
  const AContext = useContext(AppContext);
  Axios.defaults.withCredentials = true;
  const [datt, setdatt] = useState({});
  const [lg, setlg] = useState("Log in");
  const [rrt, setrrt] = useState({});
  const [storeiv, setstoreiv] = useState("");
  const [mydate, setmydate] = useState("");
  const [num, setnum] = useState(0);

  useEffect(() => {
    AContext.setLogged(false);
  }, []);

  useEffect(() => {
    if (AContext.logged) {
      // getdaa();
    }

    hinit();

    if (datt.username) {
      setlg("Log Out");
    }
  }, [AContext.logged]);

  const ssnum = (x) => {
    setnum(x);
  };

  const hinit = async () => {
    let yy = new Date();
    let tx = yy.getDate() + "/" + (yy.getMonth() + 1) + "/" + yy.getFullYear();
    setmydate(tx);
    await Axios.get(`${process.env.REACT_APP_CLIENT_SERVER}/getinit`, {
      withCredentials: true,
      credentials: "include",
    }).then((res) => {
      setdatt(res.data);
      if (res.data) {
        AContext.setLogged(true);
      }
    });
  };

  // const getdaa = async () => {
  //   await Axios.get(`${process.env.REACT_APP_CLIENT_SERVER}/moredetails`, {
  //     withCredentials: true,
  //     credentials: "include",
  //   }).then((res) => {
  //     setrrt(res.data);
  //     setstoreiv(res.data.store);
  //   });
  // };

  const { rrt: rrtx, storeiv: storeivx } = useGetDaa(datt);

  useEffect(() => {
    if (rrtx) {
      setrrt(rrtx);
      setstoreiv(storeivx);
      AContext.setrrt(rrtx);
      AContext.setstoreiv(storeivx);
    }
  }, [rrtx, storeivx]);

  const seee = (ee) => {
    setdatt(ee);
  };

  const lgbt = (ee) => {
    setlg(ee);
  };

  const ssstore = (xx) => {
    setstoreiv(xx);
  };

  return (
    <div className="Appd">
      <Router>
        <Switch>
          <Route path="/progress">
            <Progressx />
          </Route>
          <Route path="/performance">
            {datt.username ? (
              <>
                <Nav1 id={"sales"} userd={datt} mored={rrt} leg={lg} />
                {/* <Welcome data={rrtx} store={storeivx} changestore={ssstore} /> */}
                <div className="body_body">
                  <Progressx />
                  <Perform />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/salesreport">
            {datt.username ? (
              <>
                <Nav1 id={"sales"} userd={datt} mored={rrt} leg={lg} />
                {/* <Welcome data={rrt} store={storeiv} changestore={ssstore} /> */}
                <div className="body_body">
                  <Progressx />
                  <Salesinput
                    userd={datt}
                    data={rrt}
                    store={storeiv}
                    date={mydate}
                  />
                  <Salesviewer
                    data={rrt}
                    userd={datt}
                    store={storeiv}
                    className="ux_mt20"
                  />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/accessoriesreport">
            {datt.username ? (
              <>
                <Nav1 id={"accessories"} userd={datt} mored={rrt} leg={lg} />
                {/* <Welcome data={rrt} store={storeiv} changestore={ssstore} /> */}
                <div className="body_body">
                  <Progressx />
                  <Mainaccesories
                    userd={datt}
                    data={rrt}
                    store={storeiv}
                    date={mydate}
                  />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/stockreport">
            {datt.username ? (
              <>
                <Nav1 id={"stock"} userd={datt} mored={rrt} leg={lg} />
                {/* <Welcome data={rrt} store={storeiv} changestore={ssstore} /> */}
                <div className="body_body">
                  <Progressx />
                  <Stock
                    userd={datt}
                    data={rrt}
                    store={storeiv}
                    date={mydate}
                  />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/pettyreport">
            {datt.username ? (
              <>
                <Nav1 id={"petty"} userd={datt} mored={rrt} leg={lg} />
                {/* <Welcome
                  data={rrt}
                  userd={datt}
                  store={storeiv}
                  changestore={ssstore}
                /> */}
                <div className="body_body">
                  <Progressx />
                  <Pettycash
                    userd={datt}
                    data={rrt}
                    store={storeiv}
                    date={mydate}
                  />
                  <Pettyviewer userd={datt} data={rrt} store={storeiv} />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/createuser">
            {/* {datt.username ? ( */}
            <>
              <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
              <div className="body_body">
                <AddUsers mored={rrt} userd={datt} data={rrt} />
                <Footer />
              </div>
            </>
            {/* ) : (
              <>
                 
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )} */}
          </Route>
          <Route path="/resetpwd">
            <ResetPwd />
            {/* {datt.username ? (
              <>
                <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
                <AddUsers mored={rrt} userd={datt} data={rrt} />
              </>
            ) : (
              <>
                
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )} */}
          </Route>
          <Route path="/trackit">
            <>
              {datt.username ? (
                <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
              ) : null}
              <div className="body_body">
                <AdminPanel
                  changeMenu={ssnum}
                  num={num}
                  data={datt.username}
                  mored={AContext.rrt}
                />
                {num === 0 && <FormPaneV2 data={datt.username} />}

                {num === 1 && <NewOrder mored={AContext.rrt} />}
                {num === 2 && <UpdateOrders mored={AContext.rrt} />}
                {num === 3 && <CompletedOrders mored={AContext.rrt} />}

                <Footer />
              </div>
            </>
          </Route>

          <Route path="/policies">
            <>
              {datt.username ? (
                <div>
                  <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
                  <div className="body_body">
                    <Policies />
                  </div>
                </div>
              ) : (
                <>
                  <Spinnerxx />
                  <div className="ux_jdj4psin">
                    To access this resource click <a href="/"> here </a> to
                    log-in
                  </div>
                </>
              )}

              <Footer />
            </>
          </Route>
          <Route path="/distro">
            <>
              {rrt.store === "boffice" ? (
                <div>
                  <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
                  <div className="body_body">
                    <Distro />
                  </div>
                </div>
              ) : (
                <>
                  <Spinnerxx />
                  <div className="ux_jdj4psin">
                    You do not have the access to this resource. Click
                    <a href="/"> here </a> to go back
                  </div>
                </>
              )}

              <Footer />
            </>
          </Route>

          <Route path="/changepassword">
            {datt.username ? (
              <>
                <Nav1 id={""} leg={lg} mored={rrt} userd={datt} />
                <div className="body_body">
                  <ChangePassword mored={rrt} userd={datt} />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/profile">
            {datt.username ? (
              <>
                <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <div className="body_body">
                  <Profile data={rrt} userd={datt} />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>

          <Route path="/users">
            {datt.username ? (
              <>
                <Nav1 id={""} userd={datt} mored={AContext.rrt} leg={lg} />
                <div className="body_body">
                  <Users data={AContext.rrt} userd={datt} />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                {/* <Nav1 id={""} userd={datt} mored={rrt} leg={lg} />
                <Login seee={seee} userd={datt} ln={lgbt} /> */}
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  To access this resource click <a href="/"> here </a> to log-in
                </div>
              </>
            )}
          </Route>
          <Route path="/weeklyreport">
            {datt.username && rrt?.role === "sales" ? (
              <>
                <Nav1 id={""} userd={datt} mored={AContext.rrt} leg={lg} />
                {/* <Welcome
                  data={rrt}
                  userd={datt}
                  store={storeiv}
                  changestore={ssstore}
                /> */}
                <div className="body_body">
                  <MainPage data={AContext.rrt} userd={datt} />
                  <Footer />
                </div>
              </>
            ) : (
              <>
                <Spinnerxx />
                <div className="ux_jdj4psin">
                  You do not have the access to this resource. Click
                  <a href="/"> here </a> to go back
                </div>
              </>
            )}
          </Route>
          <Route path="/">
            <MainHome
              id={""}
              datt={datt}
              rrt={AContext.rrt}
              lg={lg}
              seee={seee}
              lgbt={lgbt}
            />

            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
