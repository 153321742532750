import React from "react";
import {
  InvoiceDrawerWrapper,
  IWrapper,
  Value,
  Key,
  Line,
} from "./InvoicesSearch.style";

const InvoiceDrawer = ({ data }) => {
  const keys = [
    "invoiceNumber",
    "invoiceDate",
    "customerName",
    "email",
    "branchName",
    "quantity",
    "itemName",
    "sku",
    "itemDesc",
    "currencyCode",
    "balance",
    "subTotal",
    "total",
    "lastPaymentDate",
    "phone",
    "shippingAddress",
    "invoiceStatus",
    "cfReferral",
    "cfStatus",
  ];

  const convertKey = (str) => {
    switch (str) {
      case "adjustment":
        return "Adjustment";
      case "balance":
        return "Balance";
      case "branchName":
        return "Branch Name";
      case "cfReferral":
        return "Referral";
      case "cfStatus":
        return "Status";
      case "currencyCode":
        return "Currency Code";
      case "customerName":
        return "Customer Name";
      case "discountAmount":
        return "Discount Amount";
      case "discountPercent":
        return "Discount Percentage";
      case "email":
        return "Email";
      case "id":
        return "Index";
      case "invoiceDate":
        return "Invoice Date";
      case "itemDesc":
        return "Description";
      case "invoiceNumber":
        return "Invoice Number";
      case "invoiceStatus":
        return "Invoice Status";
      case "itemName":
        return "Item Name";
      case "itemTax":
        return "Item Tax";
      case "lastPaymentDate":
        return "Last Payment Date";
      case "phone":
        return "Phone";
      case "quantity":
        return "Quantity";
      case "shippingAddress":
        return "Shipping Address";
      case "sku":
        return "Item Code";
      case "subTotal":
        return "Sub Total";
      case "taxAmount":
        return "Tax Amount";
      case "taxInclusive":
        return "Tax Inclusive";
      case "taxPercent":
        return "Tax Percentage";
      case "total":
        return "Total";
      default:
        return str;
    }
  };

  return (
    <InvoiceDrawerWrapper>
      {keys &&
        keys.length &&
        keys.map((key, i) => {
          return (
            <div key={`invoice-key-${i}`}>
              <IWrapper>
                <Key> {convertKey(key)}: </Key>
                <Value> {data[key]} </Value>
              </IWrapper>
              <Line />
            </div>
          );
        })}
    </InvoiceDrawerWrapper>
  );
};

export default InvoiceDrawer;
