import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./CompletedOrders.css";
import {
  CompletedOrdersContainer,
  TableHeaders,
  TrackTableContainer,
} from "../styles";
import { DataGrid } from "@mui/x-data-grid";

const CompletedOrders = () => {
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.length) {
      setLoading(false);
    }
  }, [data]);

  const fetchData = async () => {
    await Axios.get(`${process.env.REACT_APP_SERVER}/completedorders`).then(
      (res) => {
        if (res) {
          setdata(res.data.data);
        }
      }
    );
  };

  const tableData = data?.map((item, i) => {
    return {
      ...item,
      id: i,
    };
  });

  const columns = [
    {
      flex: 0.5,
      field: "invoiceNumber",
      headerName: <TableHeaders> Invoice Number</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "lname",
      headerName: <TableHeaders> Last Name</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "status",
      headerName: <TableHeaders> Status</TableHeaders>,
    },
    {
      flex: 0.5,
      field: "duration",
      headerName: <TableHeaders> Duration</TableHeaders>,
    },
  ];

  return (
    <div>
      <CompletedOrdersContainer>
        <TrackTableContainer height="700px">
          {tableData?.length && (
            <DataGrid
              columns={columns}
              rows={tableData}
              pageSize={10}
              hideFooterSelectedRowCount
              loading={loading}
            />
          )}
        </TrackTableContainer>
      </CompletedOrdersContainer>
    </div>
  );
};

export default CompletedOrders;
