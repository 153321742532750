import React from "react";

const ButtonDropdown = (props) => {
  return (
    <div>
      <div className="vs_pos">
        <div className="dropdown ">
          <button
            className="btn   dropdown-toggle uk_btn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {props.ddname}
          </button>
          <div
            className="dropdown-menu vs_pos1"
            aria-labelledby="dropdownMenuButton"
          >
            <a className="dropdown-item" href="/" id="1" onClick={props.jump}>
              January
            </a>
            <a className="dropdown-item" href="/" id="2" onClick={props.jump}>
              February
            </a>
            <a className="dropdown-item" href="/" id="3" onClick={props.jump}>
              March
            </a>
            <a className="dropdown-item" href="/" id="4" onClick={props.jump}>
              April
            </a>
            <a className="dropdown-item" href="/" id="5" onClick={props.jump}>
              May
            </a>
            <a className="dropdown-item" href="/" id="6" onClick={props.jump}>
              June
            </a>
            <a className="dropdown-item" href="/" id="7" onClick={props.jump}>
              July
            </a>
            <a className="dropdown-item" href="/" id="8" onClick={props.jump}>
              August
            </a>
            <a className="dropdown-item" href="/" id="9" onClick={props.jump}>
              September
            </a>
            <a className="dropdown-item" href="/" id="10" onClick={props.jump}>
              October
            </a>

            <a className="dropdown-item" href="/" id="11" onClick={props.jump}>
              November
            </a>
            <a className="dropdown-item" href="/" id="12" onClick={props.jump}>
              December
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonDropdown;
