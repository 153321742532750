import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";

const useGetReservedDeals = (limit, offset) => {
  const [data, setData] = useState();

  const fetchDeals = useCallback(async () => {
    return await Axios.post(
      `${process.env.REACT_APP_DEALS_SERVER}/dealql`,
      {
        query: `
      query SavedReservedDeal($limit: Int, $offset: Int) { 
        getSavedReservedDeal(limit: $limit, offset: $offset) {
          count
          limit
          numPages
          offset
          data {
            reference
            dealId
            itemId
            address1
            address2
            city
            state
            email
            fname
            lname
            phoneNumber
            image
            onlineStoreUrl
            ringSize
            title
            dealPrice
            itemPrice
            currencyCode
            expiryDate
            orderDate
            status
            transactionId
            id
            Country
          }
        }
      }
      
          
        `,
        variables: {
          offset,
          limit,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [limit, offset]);

  useEffect(() => {
    fetchDeals().then((res) => {
      if (res) {
        setData(res.data.data.getSavedReservedDeal);
      }
    });
  }, [fetchDeals]);

  return {
    data,
  };
};

export default useGetReservedDeals;
