import React from "react";
import FormInput from "./FormInput";
import Viewer from "./Viewer";

import "./Main.css";

const Main = (props) => {
  return (
    <div>
      <div className="container gh45a">
        <FormInput
          userd={props.userd}
          data={props.data}
          store={props.store}
          date={props.date}
        />
        <Viewer userd={props.userd} data={props.data} store={props.store} />
      </div>
    </div>
  );
};

export default Main;
