import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./AddUsers.css";
import Spinnerxx from "../Utils/Spinnerxx";

const AddUsers = (props) => {
  Axios.defaults.withCredentials = true;
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [store, setstore] = useState("Select Store");
  const [role, setrole] = useState("Select Role");
  const [err, seterr] = useState(false);
  const [txt, settxt] = useState("Form not filled properly");
  const [ready, setready] = useState(false);
  const [snum, setsnum] = useState("");

  useEffect(() => {}, []);

  useEffect(() => {
    if (fname && lname && password) {
      if (store !== "Select Store" && role !== "Select Role") {
        var emailCheck =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
        if (emailCheck.test(email)) {
          document.getElementById("signup_c").style.backgroundColor =
            "rgb(222,69,69)";
          setready(true);
        }
      }
    }
  }, [fname, lname, password, store, role, email, snum]);

  let btn_style = {
    backgroundColor: "grey",
  };

  const reset = () => {
    setemail("");
    setfname("");
    setlname("");
    setsnum("");
    setpassword("");
    setstore("Select Store");
    setrole("Select Role");
    document.getElementById("signup_c").style.backgroundColor = "grey";
  };

  const AUsubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      fname: fname,
      lname: lname,
      email: email,
      password: password,
      store: store,
      role: role,
      snum: snum,
    };
    if (ready) {
      seterr(false);
      await Axios.post(
        `${process.env.REACT_APP_CLIENT_SERVER}/createuser/reg23984iejdh`,
        {
          data,
        }
      )
        .then((res) => {
          if (res.data.data === "Error") {
            settxt("Error");
            // document.getElementById("txtid").style.backgroundColor = "red"
            seterr(true);
          } else {
            settxt("User Added");
            // document.getElementById("txtid").style.backgroundColor = "rgb(189, 250, 189)"
            seterr(true);
            reset();
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    } else {
      seterr(true);
    }
  };

  const AUchangeHandler = (e) => {
    e.preventDefault();
    let id = e.target.id;
    let value = e.target.value;

    switch (id) {
      case "snum":
        setsnum(value);
        break;
      case "fname":
        setfname(value);
        break;
      case "lname":
        setlname(value);
        break;
      case "email":
        setemail(value.toLowerCase());
        break;
      case "password":
        setpassword(value);
        break;

      case "yaba":
        setstore("yaba");
        break;
      case "abuja":
        setstore("abuja");
        break;
      case "lekki":
        setstore("lekki");
        break;
      case "accra":
        setstore("accra");
        break;
      case "boffice":
        setstore("boffice");
        break;
      case "sales":
        setrole("sales");
        break;
      case "supervisor":
        setrole("supervisor");
        break;
      case "accounts":
        setrole("accounts");
        break;
      case "admin":
        setrole("admin");
        break;

      default:
        break;
    }
  };

  if (props.data.role === "admin") {
    return (
      <div>
        <div className="row register-form">
          <div className="col-md-8 offset-md-2">
            <form className="custom-form">
              <h1 id="sign_add">Add Users</h1>
              {err ? (
                <div id="txtid" className="au_txt">
                  {txt}
                </div>
              ) : null}

              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="name-input-field"
                  >
                    First Name
                  </label>
                </div>
                <div className="col-sm-6 input-column">
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    value={fname}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="name-input-field"
                  >
                    Last Name
                  </label>
                </div>
                <div className="col-sm-6 input-column">
                  <input
                    type="text"
                    className="form-control"
                    id="lname"
                    value={lname}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="email-input-field"
                  >
                    Email
                  </label>
                </div>
                <div className="col-sm-6 input-column">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="pawssword-input-field"
                  >
                    Password
                  </label>
                </div>
                <div className="col-sm-6 input-column">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="name-input-field"
                  >
                    Staff Number
                  </label>
                </div>
                <div className="col-sm-6 input-column">
                  <input
                    type="text"
                    className="form-control"
                    id="snum"
                    value={snum}
                    onChange={AUchangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="dropdown-input-field"
                  >
                    Store
                  </label>
                </div>
                <div className="col-sm-4 input-column">
                  <div className="dropdown">
                    <button
                      className="btn btn-light dropdown-toggle signup_txt"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {store.toUpperCase()}
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        id="yaba"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Yaba Store
                      </a>
                      <a
                        className="dropdown-item"
                        id="abuja"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Abuja Store
                      </a>
                      <a
                        className="dropdown-item"
                        id="lekki"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Lekki Store
                      </a>
                      <a
                        className="dropdown-item"
                        id="accra"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Accra Store
                      </a>
                      <a
                        className="dropdown-item"
                        id="boffice"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Back Office
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-sm-4 label-column">
                  <label
                    className="col-form-label signup_txt"
                    htmlFor="dropdown-input-field"
                  >
                    Role
                  </label>
                </div>
                <div className="col-sm-4 input-column">
                  <div className="dropdown">
                    <button
                      className="btn btn-light dropdown-toggle signup_txt"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {role.toUpperCase()}
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        id="sales"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Sales Rep
                      </a>
                      <a
                        className="dropdown-item"
                        id="supervisor"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Supervisor
                      </a>
                      <a
                        className="dropdown-item"
                        id="accounts"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Accounts
                      </a>
                      <a
                        className="dropdown-item"
                        id="admin"
                        href="/"
                        onClick={AUchangeHandler}
                      >
                        Admin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button
                style={btn_style}
                className="btn btn-light submit-button signup_txt btn-disabled"
                id="signup_c"
                type="button"
                onClick={AUsubmitHandler}
              >
                Submit Form
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerx">
        <Spinnerxx />
        <div className="ux_jdj4psin">
          You are not authorized to view this page
        </div>
      </div>
    );
  }
};

export default AddUsers;
