import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";

const useGetReservedDealsById = (id) => {
  const [data, setData] = useState();

  const fetchDeals = useCallback(async () => {
    if (id && id !== "") {
      return await Axios.post(
        `${process.env.REACT_APP_DEALS_SERVER}/dealql`,
        {
          query: `
              query GetSavedReservedDealById($getSavedReservedDealByIdId: String) {
                  getSavedReservedDealById(id: $getSavedReservedDealByIdId) {
                    count
                    data {
                      reference
                      dealId
                      itemId
                      address1
                      address2
                      city
                      state
                      email
                      fname
                      lname
                      phoneNumber
                      image
                      onlineStoreUrl
                      ringSize
                      title
                      dealPrice
                      itemPrice
                      currencyCode
                      expiryDate
                      orderDate
                      status
                      transactionId
                      id
                      Country
                    }
                    limit
                    numPages
                    offset
                  }
                }
              `,
          variables: {
            getSavedReservedDealByIdId: id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }, [id]);

  useEffect(() => {
    fetchDeals().then((res) => {
      if (res) {
        setData(res.data.data.getSavedReservedDealById);
      }
    });
  }, [fetchDeals]);

  return {
    data,
  };
};

export default useGetReservedDealsById;
